import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import classes from './Message.module.scss';

import { ReactComponent as IconSuccess } from '../../images/icon-circle-check.svg';
import { ReactComponent as IconWarning } from '../../images/icon-warning.svg';
import { ReactComponent as IconCross } from '../../images/icon-cross.svg';
import { ReactComponent as IconSaving } from '../../images/icon-sync.svg';



const Message = (props) => {

    const [ messageClasses, setMessageClasses ] = useState(classes.Message + " " +  classes.Message__Hidden)

    const messageRef =  useRef();

    useEffect(() => {
        if(props.message){
            setMessageClasses(classes.Message)
            const timer = setTimeout(() => {
                setMessageClasses(classes.Message + " " +  classes.Message__Hidden)
           }, 5000);
           return () => {
               clearTimeout(timer);
           }
        }
    }, [props.message]); 

    const closeMessageHandler = () => {
        setMessageClasses(classes.Message + " " +  classes.Message__Hidden)
    }


    return ReactDOM.createPortal(
        <>
        <div className={messageClasses} ref={messageRef}>
            {
                props.message ?
                    <div className={classes.Message__Inner}>
                        {
                            props.message.type ?
                                <div className={props.message.type === "success" ? classes.Message__Icon + " " + classes.Message__Success : props.message.type ==="save" ? classes.Message__Icon + " " + classes.Message__Save : classes.Message__Icon + " " + classes.Message__Error }>
                                    {
                                        props.message.type === "success" ?
                                            <IconSuccess />
                                        : props.message.type === "error" ?
                                            <IconWarning />
                                        : props.message.type === "save" ?
                                            <IconSaving />
                                        : null
                                    }
                                </div>
                            : null
                        }

                        <div>
                            <h2 className={classes.Message__Title}>{props.message.title}</h2>
                            <div className={classes.Message__Message}>{props.message.message}</div>
                        </div>
                        <div className={classes.Message__Close} title="Close Message" onClick={() => closeMessageHandler()}>
                            <IconCross />
                        </div>
                    </div>
                : null
            }
        </div>
        </>, document.getElementById('message')
    )    
}

export default Message