import React, { useEffect, useState } from 'react';
import classes from './PeoplePicker.module.scss';
import axios from 'axios';
import Loader from '../Loader/Loader';

const PeoplePicker = (props) => {
    const [ results, setResults ] = useState(null);
    const [ searching, setSearching ] = useState(false);
    const [ query, setQuery ] = useState("");
    const [ selected, setSelected ] = useState([]);
    const [ hideResults, setHideResults ] = useState(true);

    useEffect(() => {
        if(query){
            const timer = setTimeout(() => {
                setSearching(true);
                axios.put(props.apiRoute, {
                    query: query,
                    group: props.groupIdSearch ? props.groupIdSearch : null
                })
                .then(res => {
                    setSearching(false);
                    if(props.filterUser) {
                        setResults(res.data.people.filter(item => item.userPrincipalName !== props.filterUser));
                    } else {
                        setResults(res.data.people);
                    }
                })
                .catch(err => {
                    setSearching(false);
                    console.log(err);
                });
              }, 250);
              return () => clearTimeout(timer);
        }
    }, [query])

    const setSelectedHandler = (person) => {
        if(!selected.find(item => item.id === person.id)){
            if(props.multiSelect){
                setSelected(prev => ([
                    ...prev,
                    person
                ]))
            } else { 
                setSelected([person])
            }
        }
    }

    useEffect(() => {
        props.selectedPeople(selected)
    }, [selected])

    const removeSelectedPersonHandler = (person) => {
        const filtered = selected.filter((item) => item.id !== person.id)
        setSelected(filtered)
    }

    const resultsClassName =  [classes["people-picker__results"], hideResults || query === "" ? classes["people-picker__results--hidden"] : null].join(' ')

    return(
        <div className={classes["people-picker"]}>
            {
                <div className={classes["people-picker__selected-label"]}>
                    {
                        props.defaultSelected ?
                            props.defaultSelected
                        : null
                    }
                </div>
            }
            {
                props.multiSelect ?
                    selected.length ?
                        <div className={classes["people-picker__selected"]}>
                            {
                                selected.map(person => 
                                    <div title="Remove this person" onClick={ () => removeSelectedPersonHandler(person) }>{ person.displayName }</div>    
                                )
                            }
                        </div>
                    : null
                : null
            }
            <div className={classes["people-picker__search"]} >
                <input 
                    placeholder='Search people...'
                    onChange={(e) => { setQuery(e.target.value) }} 
                    onClick={() => setHideResults(false)} 
                    onFocus={() => setHideResults(false)} />
                {
                    hideResults === false ?
                        <div className={classes["people-picker__close"]} onClick={() => setHideResults(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>    
                        </div>
                    : null 
                }
            </div>
            <div className={resultsClassName}>
                {
                    searching ?
                        <>
                            { props.searchingMessage }
                            { 
                                props.showSpinner ?
                                    <div className={classes["people-picker__loader"]}>
                                        <Loader />
                                    </div>
                                : null
                            }
                        </>
                    :
                    results ? 
                        results.length ?
                            results.map(person =>
                                <div className={classes["people-picker__result"]} key={person.id} onClick={() => {setSelectedHandler(person); setHideResults(true)}}> 
                                    <div className={classes["people-picker__name"]}>{ person.displayName }</div>   
                                    <div className={classes["people-picker__email"]}>{ person.userPrincipalName }</div> 
                                </div>
                            )
                        : props.noResultsMessage
                    : null
                }
            </div>
        </div>
    )
}

export default PeoplePicker;