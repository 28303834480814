import React, {useMemo} from 'react';
import Button from '../Button/Button';
import axios from '../../axios';

const UserOnLeave = (props) => {

    const userObject = useMemo(() => (
        {
            "StudentID": props.selected.onPremisesSamAccountName,
            "JobTitle": props.selected.jobTitle 
        }
    ), [])

    const userOnLeaveHandler = (e) => {
        e.preventDefault()

        props.setMessage({
            type: "save",
            title: "Processing",
            message: "This action is being processed."
        })

        axios.post(`/set-leave-of-absense/${props.user.id}`, userObject)
        .then(res => {
            props.setMessage({
                type: "success",
                title: "Action Processed",
                message: "This action has been processed."
            })

            props.setDialogOpen(false)
        })
        .catch(err => {
            props.setMessage({
                type: "success",
                title: "Action Processed",
                message: "This action has been processed."
            })

            props.setDialogOpen(false)           
        })
    }

    return(
        <div>
            <p>Are you sure you to change the status of this user to be on "Leave of Absense"?</p>
            <Button onClick={e => userOnLeaveHandler(e)}>Set User On Leave of Absense</Button>
        </div>
    )
}

export default UserOnLeave;