import React, { useState, useRef } from 'react';
import classes from './StudentContainer.module.scss';
import Header from '../Header/Header';
import acceptableImages from '../../images/acceptable-images.PNG';
import unacceptableImages from '../../images/unacceptable-images.PNG';
import Button from '../Button/Button';
import axios from '../../axios';
import '../../styles/students.scss';


const StudentContainer = (props) => {

    //  ----------------- Image upload ----------------- //
    const [ image, setImage ] = useState(null);
    const [ imageMessage, setImageMessage ] = useState(null);
    const [ fileType, setFileType ] = useState(null)

    const onImageChangeHandler = (event) => { 
        setImage(null)
        if (event.target.files && event.target.files[0]) {
            if(event.target.files[0].size > 500000) {
                setImageMessage({message:"Image exceeds 500 KB, please select a smaller image.", type: "error"})
            } else if(event.target.files[0].type === "image/png" || event.target.files[0].type === "image/jpeg") { 
                setFileType("." + event.target.files[0].type.split("/")[1])
                let reader = new FileReader();
                reader.onload = (e) => {
                  setImage(e.target.result);
                  setImageMessage(null)
                };
                reader.readAsDataURL(event.target.files[0]);
            } else { 
                setImageMessage({message:"Images can only be PNG or JPG file types. Please try another file.", type: "error"})
            }
        }
    }

    const submitImageHandler = () =>{
        if(image){

            props.setMessage({
                type: "save",
                title: "Uploading image",
                message: "Your image is being uploaded, please wait"
            })

            axios.post('/upload-student-image', {
                "Photo": image,
                "StudentID": props.user.email.split("@")[0],
                "FileType": fileType
            })
            .then(() => {
                props.setMessage({
                    type: "success",
                    title: "Image uploaded",
                    message: "Image has been successfully uploaded"
                })
                setImageMessage({message: "Thank you, your image has been uploaded.", type: "success"})
                setImage(null)
            })
            .catch(() => {
                props.setMessage({
                    type: "error",
                    title: "Image not uploaded",
                    message: "Sorry, there was a problem and your image wasn't uploaded. Please try again"
                })
                setImageMessage({message: "Your image could not be uploaded. Plese try again.", type: "error"})
            })
        }
    }    


    return(
        <>
        {
            props.hideHeader ?
            null :
            <Header 
                title={"Student Photo Upload"} />        
        }

        <div className={classes.Container}>
            <input type="file" accept="image/*" id="file-input" onChange={(e) => onImageChangeHandler(e) } />
            <div className={classes.Container__ImageArea}>
                {
                    imageMessage ?
                        <div className="ImageError" data-type={imageMessage.type}>{imageMessage.message}</div>
                    : null
                }
                {
                    image ?
                        <>
                            <img className={classes.Container__ImagePreview} src={image} alt="Receipt" />
                            <br />
                            <Button onClick={() => submitImageHandler()}>Upload Your Image</Button>
                        </>
                    : null
                }
            </div>

            <p>Please now upload a passport-style photograph of yourself, which must:</p>
            <ul>
                <li>a portrait headshot</li>
                <li>a current true likeness</li>
                <li>showing your full face</li>
                <li>facing forward without a hat</li>
                <li>against a plain and light-coloured background</li>
            </ul>

            <p>A photograph not meeting all of the above requirements will be rejected.</p>
            <p>The image file should be JPG or PNG format and not more than 0.5MB (500 KB) in size. Ideal h x w dimensions in pixels 252 x 200</p>

            <i>Examples</i>
            <p>The following three photos are acceptable:</p>
            <img src={acceptableImages} alt="Acceptable Images" />
            <p>The following are <strong>not</strong> acceptable and will be rejected.</p>
            <img src={unacceptableImages} alt="Unacceptable Images" />
        </div>
        </>
    )
}

export default StudentContainer;