import React, { useState, useEffect } from 'react';

import classes from './AddImage.module.scss';
import axios from '../../axios';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';

const AddImage = (props) => {

    const [ image, setImage ] = useState(null)
    const [ imageMessage, setImageMessage ] = useState(null);
    const [ fileType, setFileType ] = useState(null);
    const [ downloaded, setDownloaded ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    const getUserImage = () => {
        setLoading(true)
        setDownloaded(null)
        axios.post('/get-user-image', { id: props.id })
        .then(res=> {
            if(res.data.photo !=="")
            setDownloaded("data:image/png;base64," + res.data.photo)
            setLoading(false)
        })
        .catch(err => {console.log(err); setDownloaded(null); setLoading(false)})
    }

    useEffect(() => {  getUserImage()}, [props.selected])


    const onImageChangeHandler = (event) => { 
        setImage(null)
        if (event.target.files && event.target.files[0]) {
            if(event.target.files[0].size > 500000) {
                setImageMessage({message:"Image exceeds 500 KB, please select a smaller image.", type: "error"})
            } else if(event.target.files[0].type === "image/png" || event.target.files[0].type === "image/jpeg") { 
                setFileType("." + event.target.files[0].type.split("/")[1])
                let reader = new FileReader();
                reader.onload = (e) => {
                  setImage(e.target.result);
                  setImageMessage(null)
                };
                reader.readAsDataURL(event.target.files[0]);
            } else { 
                setImageMessage({message:"Images can only be PNG or JPG file types. Please try another file.", type: "error"})
            }
        }
    }    


    const submitImageHandler = () =>{
        if(image){

            props.setMessage({
                type: "save",
                title: "Uploading image",
                message: "Your image is being uploaded, please wait"
            })

            axios.post('/add-image', {
                "Photo": image,
                "StudentID": props.id,
                "FileType": fileType
            })
            .then(() => {
                props.setMessage({
                    type: "success",
                    title: "Image uploaded",
                    message: "Image has been successfully uploaded"
                })      
                props.setDialogOpen(false)                     
            })
            .catch(() => {
                props.setMessage({
                    type: "error",
                    title: "Image not uploaded",
                    message: "Sorry, there was a problem and your image wasn't uploaded. Please try again"
                })
            })
        }
    }

    return(
        <div className={classes.AddImage}>
            {
                loading ?
                <Loader />
                :
                downloaded ? 
                    <>
                    <h3>Existing Image</h3>
                    <img className={classes.Downloaded} src={downloaded} />
                    </>
                : null
            }
            <h3>Add New Image</h3>
            <input type="file" accept="image/*" onChange={(e) => onImageChangeHandler(e)} />
            {
                imageMessage ?
                    <div className="ImageError" data-type={imageMessage.type}>{imageMessage.message}</div>
                : null                
            }
            {
                image ?
                    <>
                        <img src={image} width="200" />
                        <Button onClick={() => submitImageHandler()}>Submit new image</Button>
                    </>
                : null
            }
        </div>
    )
}

export default AddImage