import React from 'react';
import EditStudent from './EditStudent';
import EditStaff from './EditStaff';


const EditUser = (props) => {
    return(
        <div>
            {
                props.selected.personType === "Student" ?
                    <EditStudent selected={props.selected} user={props.user} setMessage={props.setMessage} setDialogOpen={props.setDialogOpen} />
                : props.selected.personType === "Staff" ?
                    <EditStaff selected={props.selected} user={props.user} setMessage={props.setMessage} setDialogOpen={props.setDialogOpen} />
                : null
            }
        </div>
    )
}

export default EditUser;
