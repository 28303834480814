import React, { useState } from 'react';

import AddStudent from './AddStudent';
import AddStaff from './AddStaff';

import classes from './AddUsers.module.scss';

const AddUser = (props) => {

    const [ userType, setUserType ] = useState("Staff");

    const addUserTypeHandler = (type) => {
        setUserType(type)
    }

    return(
        <>
        <div className={classes.Tabs}>
            <div className={[classes.Tabs__Item, userType === "Staff" ? classes.Tabs__Item__Active : null].join(' ')} onClick={(e) => addUserTypeHandler(e.target.id)} id="Staff">Add Staff</div>
            <div className={[classes.Tabs__Item, userType === "Student" ? classes.Tabs__Item__Active : null].join(' ')} onClick={(e) => addUserTypeHandler(e.target.id)} id="Student">Add Student</div>
        </div>
        
        {
            userType === "Staff" ?
                <AddStaff 
                    addStaffObject={props.addStaffObject}
                    setAddStaffObject={props.setAddStaffObject} 
                    setMessage={props.setMessage}
                    closeDialogHandler={props.closeDialogHandler} 
                    user={props.user} />
            : userType === "Student" ?
                <AddStudent 
                    addStudentObject={props.addStudentObject}
                    setAddStudentObject={props.setAddStudentObject} 
                    setMessage={props.setMessage}
                    closeDialogHandler={props.closeDialogHandler} 
                    user={props.user} />
                    
            : null
        }
        </>
    )
}

export default AddUser;