import React, { useState, useEffect} from 'react';
import axios from '../../axios';

import Container from '../../components/Container/Container';
import StudentContainer from '../../components/StudentContainer/StudentContainer';
import Message from '../../components/Message/Message';
import Spinner from '../../components/Loader/Loader'

import '../../styles/image-error.scss';

const Dashboard = (props) => {

  // ==================================================================================================
  // = Find out who the user is
  // ==================================================================================================
  const [ userLevel, setUserLevel ] = useState(null)

  useEffect(() => {

  if(props.user){
    if(props.user.id){
      axios.post(`/current-user-info/${props.user.id},${process.env.REACT_APP_AD_ADMIN_GROUP_ID},${process.env.REACT_APP_AD_READ_ONLY_ADMIN_GROUP_ID}`)
       .then(res => {
        setUserLevel(res.data.data)
      })
       .catch(err => console.log(err))
    }
  }
  }, [props.user])  

    // ==================================================================================================
    // = Message
    // ==================================================================================================
    const [ message, setMessage ] = useState(null)


  return (
    <>
          {
              message ?
                  <Message message={message} />
              : null
          }    
      {
        userLevel === null ?
          <Spinner />
        : userLevel === "admin" || userLevel === "readonly" ?
          <Container setMessage={setMessage} user={props.user} userLevel={userLevel} />
        :
          <StudentContainer setMessage={setMessage} user={props.user} />
      }

    </>
  );
}

export default Dashboard;
