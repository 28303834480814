import React, {useEffect, useState} from 'react';
import axios from '../../axios';
import moment from 'moment';

import classes from './UserForm.module.scss';
import Button from '../Button/Button';

const AddStaff = (props) => {

    const [firstname, setFirstname] =  useState('');
    const [lastname, setLastname] = useState('');
    const [ programmeCode, setProgrammeCode] = useState(null);
    const [ selectedProgrammeCode, setSelectedProgrammeCode ] = useState(null);

    useEffect(() => {
        axios.post("get-user-creation-table-stroage", {partitionKey: "staffassignedgroups", table: "RCAStaffGroupAssignments"})
        .then(res => setProgrammeCode(res.data.data.value))
        .catch(err => console.log(err))
    }, [])    

    useEffect(() => {
        if(selectedProgrammeCode){
            const foundObject = programmeCode.find(item => item.Selection === selectedProgrammeCode)
            onChangeHandler(foundObject.AD_Department, "Department")
            onChangeHandler(foundObject.AD_Office, "Office")
        }
    }, [selectedProgrammeCode])
    
    const onChangeHandler = (value, target) => {
       props.setAddStaffObject(prev => (
            {
                ...prev,
                [target]: value
            }
        ))
    }

    const onChangeDateHandler = (value, target) => {
        props.setAddStaffObject(prev => (
             {
                 ...prev,
                 [target]: moment(value).format("DD/MM/YYYY")
             }
         ))
     }    

    useEffect(() => {
        props.setAddStaffObject(prev => (
            {
                ...prev,
                DisplayName: firstname + " " + lastname
            }
        ))
        // if(firstname || lastname) {
        //     props.setAddStaffObject(prev => (
        //         {
        //             ...prev,
        //             StudentID: firstname.toLowerCase() + "." + lastname.toLowerCase()
        //         }
        //     ))
        // } else { 
        //     props.setAddStaffObject(prev => (
        //         {
        //             ...prev,
        //             StudentID: ""
        //         }
        //     ))            
        // }
    }, [lastname, firstname])

    const sendAddUserHandler = (e) => {
        e.preventDefault()

        props.setMessage({
            type: "save",
            title: "Adding new user",
            message: "Please wait while the new user is added"
        })

        axios.post(`/add-staff/${props.user.id}`, props.addStaffObject)
        .then(res => {
            console.log(res)
            props.setMessage({
                type: "success",
                title: "User added",
                message: "A new user has been added. Please allow up to 30 minutes for the user to be provisoned in Azure"
            })
            props.closeDialogHandler()
        })
        .catch(err => {
            props.setMessage({
                type: "error",
                title: "User not added",
                message: "Sorry, there has been a problem and the user has not been added. Please try again."
            })            
        })
    }

    return(
        <>
        <p>Please fill out the below form to add a new <strong>Staff Member</strong></p>
        <br />
        <form className={classes.UserForm} onSubmit={(e) => sendAddUserHandler(e)}>
    
            <div className={classes.UserForm__Item}>
                <div>Given Name</div>
                <input value={props.addStaffObject.GivenName} type="text" required onChange={(e) => {onChangeHandler(e.target.value, "GivenName"); setFirstname(e.target.value)}} />
            </div>   
            <div className={classes.UserForm__Item}>
                <div>Surname</div>
                <input value={props.addStaffObject.Surname} type="text" required onChange={(e) => {onChangeHandler(e.target.value, "Surname"); setLastname(e.target.value)}} />
            </div> 
            <div className={classes.UserForm__Item}>
                <div>Display Name</div>
                <input disabled value={props.addStaffObject.DisplayName} />
            </div>                            
            {/* <div className={classes.UserForm__Item}>
                <div>Name</div>
                <input value={props.addStaffObject.Name} type="text" required onChange={(e) => onChangeHandler(e.target.value, "Name")} />
            </div> */}
            <div className={classes.UserForm__Item}>
                <div>Staff ID</div>
                <input value={props.addStaffObject.StudentID} type="number" onChange={(e) => onChangeHandler(e.target.value, "StudentID")} />
            </div>
            <div className={classes.UserForm__Item}>
                <div>HESA ID</div>
                <input value={props.addStaffObject.HESAID} type="number" onChange={(e) => onChangeHandler(e.target.value, "HESAID")} />
            </div>
            <div className={classes.UserForm__Item}>
                <div>Programme Code</div>
                <select value={props.addStaffObject.AcademicReportingCode} required onChange={(e) => {onChangeHandler(e.target.value, "AcademicReportingCode"); onChangeHandler(e.target.value, "ProgrammeCode"); setSelectedProgrammeCode(e.target.value)}}>
                    <option value="" disabled>Select...</option>
                    {
                        programmeCode ?
                            programmeCode.map(item => 
                                <option key={item.Selection} value={item.Selection}>{item.Selection}</option>
                            ) 
                        : null
                    }
                </select>
            </div>
            <div className={classes.UserForm__Item}>
                <div>Contract Type</div>
                <select value={props.addStaffObject.AdminDescription} required onChange={(e) => {onChangeHandler(e.target.value, "AdminDescription"); }}>
                    <option value="" disabled>Select...</option>
                    <option value="FTE">FTE</option>
                    <option value="VL/Casual">VL/Casual</option>
                    <option value="Off-payroll">Off-Payroll</option>
                </select>                
            </div>
            {/* <div className={classes.UserForm__Item}>
                <div>Category</div>
                <select value={props.addStaffObject.Category} required onChange={(e) => onChangeHandler(e.target.value, "Category")}>
                    <option value="" disabled>Select...</option>
                    <option value="Administration">Administration</option>
                    <option value="Buildings &amp; Estates">Buildings &amp; Estates</option>
                    <option value="Estates &amp; Campus Operations">Estates &amp; Campus Operations</option>
                    <option value="Finance">Finance</option>
                    <option value="ILTS">ILTS</option>
                    <option value="Services &amp; Support">Services &amp; Support</option>
                    <option value="School of Communication">School of Communication</option>
                    <option value="School of Architecture">School of Architecture</option>
                    <option value="School of Arts &amp; Humanities">School of Arts &amp; Humanities</option>
                    <option value="School of Design">School of Design</option>
                    <option value="Graduate Diploma Art &amp; Design">Graduate Diploma Art &amp; Design</option>
                    <option value="Research &amp; Innovation">Research &amp; Innovation</option>
                </select>
            </div> */}
            {/* <div className={classes.UserForm__Item}>
                <div>Course</div>
                <input value={props.addStaffObject.Course} type="text" required onChange={(e) => onChangeHandler(e.target.value, "Course")} />
            </div>   */}
            {/* <div className={classes.UserForm__Item}>
                <div>Description</div>
                <input value={props.addStaffObject.Description} type="text" required onChange={(e) => onChangeHandler(e.target.value, "Description")} />
            </div>                                        */}
            {/* <div className={classes.UserForm__Item}>
                <div>Start Date</div>
                <input type="date" required onChange={(e) => onChangeDateHandler(e.target.value, "StartMonth")} />
            </div>               */}
            <div className={classes.UserForm__Item}>
                <div>Expiry Date</div>
                <input type="date" onChange={(e) => onChangeDateHandler(e.target.value, "ExpiryDate")} />
            </div>              
            <div className={classes.UserForm__Item}>
                <div>Job Title</div>
                <input value={props.addStaffObject.JobTitle} type="text" required onChange={(e) => onChangeHandler(e.target.value, "JobTitle")} />
            </div>     
            <div className={classes.UserForm__Item}>
                <div>Department</div>
                <input value={props.addStaffObject.Department} type="text" required onChange={(e) => onChangeHandler(e.target.value, "Department")} />
            </div> 
            <div className={classes.UserForm__Item}>
                <div>Description</div>
                <input value={props.addStaffObject.Description} type="text" required onChange={(e) => onChangeHandler(e.target.value, "Description")} />
            </div>             
            <div className={classes.UserForm__Item}>
                <div>Office</div>
                <input value={props.addStaffObject.Office} type="text" required onChange={(e) => onChangeHandler(e.target.value, "Office")} />
            </div>             
            {/* <div className={classes.UserForm__Item}>
                <div>City</div>
                <input value={props.addStaffObject.City} type="city" required onChange={(e) => onChangeHandler(e.target.value, "City")} />
            </div>   */}
            <div className={classes.UserForm__Item}>
                <div>Personal Email</div>
                <input value={props.addStaffObject.PersonalEmail} type="email" required onChange={(e) => onChangeHandler(e.target.value, "PersonalEmail")} />
            </div>              
            <div className={classes.UserForm__Item}>
                <div>Phone Extension</div>
                <input value={props.addStaffObject.PhoneExtension} type="number"  onChange={(e) => onChangeHandler(e.target.value, "PhoneExtension")} />
            </div>
            <div>
                <input type="checkbox" name="mailbox" defaultChecked={props.addStaffObject.CreateMailbox} onChange={(e) => onChangeHandler(e.target.checked, "CreateMailbox")} />
                <label for="mailbox">Do not create mailbox</label>
            </div>
            <br /><br />
            <Button type="submit">Add New Staff Member</Button> 
            <div className={classes.UserForm__Message}>
                Please note: It can take up to 30 minutes for new users to be visible in the table.
            </div>                                                                                                     
        </form>
        </>
     
    )
}

export default AddStaff;