import React, { useState, useEffect } from 'react';
import axios from '../../axios';
import moment from 'moment';

import classes from './UserForm.module.scss';
import Button from '../Button/Button';

const EditStudent = (props) => {


    const [ originalSelected, setOriginalSelected ] = useState({...props.selected});
    const [firstname, setFirstname] =  useState(originalSelected.givenName);
    const [lastname, setLastname] = useState(originalSelected.surname);
    const [ programmeCode, setProgrammeCode] = useState(null);
    const [ selectedProgrammeCode, setSelectedProgrammeCode ] = useState("");

    console.log(props.selected)

    useEffect(() => {
        axios.post("get-user-creation-table-stroage", {partitionKey: "studentgroups", table: "RCAUserGroups"})
        .then(res => {
            setProgrammeCode(res.data.data.value);
        })
        .catch(err => console.log(err))
        onChangeHandler(originalSelected.companyName, "companyName");

        if(originalSelected.city){
            if(originalSelected.city.includes("January")){
                setOriginalSelected((prev) => ({
                    ...prev,
                    companyName: originalSelected.companyName + "-January"
                }))
            }
    
            if(originalSelected.city.includes("July")){
                setOriginalSelected((prev) => ({
                    ...prev,
                    companyName: originalSelected.companyName + "-July"
                }))
            }        
        }

    }, [])


    useEffect(() => {
        if(selectedProgrammeCode){
            const foundObject = programmeCode.find(item => item.RowKey === selectedProgrammeCode);


            // if(selectedProgrammeCode.includes("January") || selectedProgrammeCode.includes("July")){
            //     console.log("this is a grad dip")
            //     const code = selectedProgrammeCode.split(selectedProgrammeCode.includes("January") ? "-January" : "-July" )[0];
            //     console.log(code, programmeCode)                
            //     foundObject = programmeCode.find(item => item.AcademicReportingCode_x002e_Description === code);
            
            //     onChangeHandler(foundObject.Department, "department")
            //     onChangeHandler(foundObject.PhysicalDeliveryOfficeName, "officeLocation")
            //     onChangeHandler(foundObject.Degree + " " + foundObject.PhysicalDeliveryOfficeName + " " + moment().format("YYYY"), "city")
            //     onChangeHandler(foundObject.Degree + " " + "Student" + " " + moment().format("YYYY"), "jobTitle")

            // } else {

            // }

            if(foundObject.Mailgroup1.includes("January")) {
                onChangeHandler(foundObject.Degree + " " + foundObject.PhysicalDeliveryOfficeName + " " + moment().format("YYYY") + " January", "city")
                onChangeHandler(foundObject.Degree + " " + "Student" + " " + moment().format("YYYY") + " January", "jobTitle")
            } 

            if(foundObject.Mailgroup1.includes("July")) {
                onChangeHandler(foundObject.Degree + " " + foundObject.PhysicalDeliveryOfficeName + " " + moment().format("YYYY") + " July", "city")
                onChangeHandler(foundObject.Degree + " " + "Student" + " " + moment().format("YYYY") + " July", "jobTitle")
            } 
            
            if(foundObject.Mailgroup1.includes("January") == false && foundObject.Mailgroup1.includes("July") === false) {
                onChangeHandler(foundObject.Degree + " " + foundObject.PhysicalDeliveryOfficeName + " " + moment().format("YYYY"), "city")
                onChangeHandler(foundObject.Degree + " " + "Student" + " " + moment().format("YYYY"), "jobTitle")
            } 
                 
            onChangeHandler(foundObject.Department, "department")
            onChangeHandler(foundObject.PhysicalDeliveryOfficeName, "officeLocation")
            // onChangeHandler(foundObject.Degree + " " + foundObject.PhysicalDeliveryOfficeName + " " + moment().format("YYYY"), "city")
            // onChangeHandler(foundObject.Degree + " " + "Student" + " " + moment().format("YYYY"), "jobTitle")

        }
    }, [selectedProgrammeCode])

    const onChangeDateHandler = (value, target) => {
        props.setAddStudentObject(prev => (
             {
                 ...prev,
                 [target]: moment(value).format("DD/MM/YYYY")
             }
         ))
     }   

    const onChangeHandler = (value, target) => {
        setOriginalSelected(prev => (
            {
                ...prev,
                [target]: value
            }
        ))
    }

    useEffect(() => {
        setOriginalSelected(prev => (
            {
                ...prev,
                displayName: firstname + " " + lastname
            }
        ))
    }, [lastname, firstname])    



    const sendEditUserHandler = (e) => {
        e.preventDefault();

        props.setMessage({
            type: "save",
            title: "Updating user information",
            message: "User information is being saved, please wait"
        })


        const editUserObject = {
            "Surname": originalSelected.surname,
            "StudentID": originalSelected.onPremisesSamAccountName,
            "ProgrammeCode": originalSelected.companyName,
            "PersonalEmail": originalSelected.otherMails,
            "JobTitle": originalSelected.jobTitle,
            "GivenName": originalSelected.givenName,
            "DisplayName": originalSelected.displayName,
            "Department": originalSelected.department,
            "City": originalSelected.city,
            // "Description": originalSelected.description,
            "ExpiryDate": "01/01/2020",
            "Office": originalSelected.officeLocation,
            "PhoneExtension": originalSelected.businessPhones,
            "Email": originalSelected.mail
        }


        axios.post(`/edit-student/${props.user.id}`, editUserObject)
        .then(res => {
            props.setMessage({
                type: "success",
                title: "User has been updated",
                message: "The user has been updated. Please allow up to 30 minutes for the user to be provisoned in Azure"
            })
        })
        .catch(err => {
            props.setMessage({
                type: "success",
                title: "User was not updated",
                message: "Sorry, there has been a problem and the user has not been updated. Please try again."
            })            
        })
    }

    return(
        <form className={classes.UserForm} onSubmit={(e) => sendEditUserHandler(e)}>
            <div className={classes.UserForm__Item}>
                <div>Given Name</div>
                <input value={originalSelected.givenName} type="text" required onChange={(e) => {onChangeHandler(e.target.value, "givenName"); setFirstname(e.target.value)}} />
            </div>   
            <div className={classes.UserForm__Item}>
                <div>Surname</div>
                <input value={originalSelected.surname} type="text" required onChange={(e) => {onChangeHandler(e.target.value, "surname"); setLastname(e.target.value)}} />
            </div> 
            <div className={classes.UserForm__Item}>
                <div>Display Name</div>
                <input disabled value={originalSelected.displayName} />
            </div>                            
            {/* <div className={classes.UserForm__Item}>
                <div>Name</div>
                <input value={originalSelected.Name} type="text" required onChange={(e) => onChangeHandler(e.target.value, "Name")} />
            </div> */}
            <div className={classes.UserForm__Item}>
                <div>Student ID</div>
                <input value={originalSelected.onPremisesSamAccountName} disabled />
            </div>  
  
            <div className={classes.UserForm__Item}>
                <div>Programme Code</div>
                <select value={originalSelected.companyName} required onChange={(e) => {onChangeHandler(e.target.value, "companyName"); onChangeHandler(e.target.value, "ProgrammeCode"); setSelectedProgrammeCode(e.target.value)}}>
                    <option value="" disabled>Select...</option>
                    {
                        programmeCode ?
                            programmeCode.map((item, index) => 
                                <option key={item.AcademicProgram_x002e_Name + " - (" + item.RowKey + ")"} value={item.RowKey}>{item.AcademicProgram_x002e_Name + " - (" + item.RowKey + ")"}</option>
                            ) 
                        : null
                    }
 
                </select>

            </div>
            {/* <div className={classes.UserForm__Item}>
                <div>Description</div>
                <input value={originalSelected.Description} type="text" required onChange={(e) => onChangeHandler(e.target.value, "Description")} />
            </div>                                        */}
            {/* <div className={classes.UserForm__Item}>
                <div>Start Date</div>
                <input type="date" required onChange={(e) => onChangeDateHandler(e.target.value, "StartMonth")} />
            </div>              
            <div className={classes.UserForm__Item}>
                <div>Expiry Date</div>
                <input type="date" onChange={(e) => onChangeDateHandler(e.target.value, "ExpiryDate")} />
            </div>               */}
            <div className={classes.UserForm__Item}>
                <div>Title</div>
                <input value={originalSelected.jobTitle} type="text" required onChange={(e) => onChangeHandler(e.target.value, "jobTitle")} />
            </div>     
            <div className={classes.UserForm__Item}>
                <div>School</div>
                <input value={originalSelected.department} type="text" required onChange={(e) => onChangeHandler(e.target.value, "department")} />
            </div> 
            <div className={classes.UserForm__Item}>
                <div>Office</div>
                <input value={originalSelected.officeLocation} type="text" required onChange={(e) => onChangeHandler(e.target.value, "officeLocation")} />
            </div>             
            <div className={classes.UserForm__Item}>
                <div>Cohort</div>
                <input value={originalSelected.city} type="city" required onChange={(e) => onChangeHandler(e.target.value, "city")} />
            </div>  
            <div className={classes.UserForm__Item}>
                <div>Personal Email</div>
                <input value={originalSelected.otherMails} type="email" required onChange={(e) => onChangeHandler(e.target.value, "otherMails")} />
            </div>              
            <div className={classes.UserForm__Item}>
                <div>Phone Extension</div>
                <input value={originalSelected.businessPhones} type="number" onChange={(e) => onChangeHandler(e.target.value, "businessPhones")} />
            </div>
            <Button type="submit">Update Student</Button> 
            <div className={classes.UserForm__Message}>
                Please note: It can take up to 30 minutes for changes to become visible.
            </div>                                    



        </form>
    )
}

export default EditStudent;
