import React, {useEffect, useState} from 'react';
import axios from '../../axios';
import moment from 'moment';

import classes from './UserForm.module.scss';
import Button from '../Button/Button';

const AddStudent = (props) => {

    const [firstname, setFirstname] =  useState('');
    const [lastname, setLastname] = useState('');
    const [ programmeCode, setProgrammeCode] = useState(null);
    const [ selectedProgrammeCode, setSelectedProgrammeCode ] = useState("")


    useEffect(() => {
        axios.post("get-user-creation-table-stroage", {partitionKey: "studentgroups", table: "RCAUserGroups"})
        .then(res => setProgrammeCode(res.data.data.value))
        .catch(err => console.log(err))
    }, [])


    useEffect(() => {
        if(selectedProgrammeCode){
            const foundObject = programmeCode.find(item => item.RowKey === selectedProgrammeCode)
            console.log(foundObject)
            onChangeHandler(foundObject.Department, "Department")
            onChangeHandler(foundObject.PhysicalDeliveryOfficeName, "Office")
            onChangeHandler(foundObject.Degree + " " + foundObject.PhysicalDeliveryOfficeName + " " + moment().format("YYYY"), "City")
            onChangeHandler(foundObject.Degree + " " + "Student" + " " + moment().format("YYYY"), "JobTitle")

        }
    }, [selectedProgrammeCode])


    const onChangeHandler = (value, target) => {
       props.setAddStudentObject(prev => (
            {
                ...prev,
                [target]: value
            }
        ))
    }

    const onChangeDateHandler = (value, target) => {
        props.setAddStudentObject(prev => (
             {
                 ...prev,
                 [target]: moment(value).format("DD/MM/YYYY")
             }
         ))
     }    

    useEffect(() => {
        props.setAddStudentObject(prev => (
            {
                ...prev,
                DisplayName: firstname + " " + lastname
            }
        ))
    }, [lastname, firstname])

    const sendAddUserHandler = (e) => {
        e.preventDefault()

        props.setMessage({
            type: "save",
            title: "Adding new user",
            message: "Please wait while the new user is added"
        })

        axios.post(`/add-student/${props.user.id}`, props.addStudentObject)
        .then(res => {
            props.setMessage({
                type: "success",
                title: "User added",
                message: "A new user has been added. Please allow up to 30 minutes for the user to be provisoned in Azure"
            })
            props.closeDialogHandler()
        })
        .catch(err => {
            props.setMessage({
                type: "error",
                title: "User not added",
                message: "Sorry, there has been a problem and the user has not been added. Please try again."
            })            
        })
    }


    return(
        <>
        <p>Please fill out the below form to add a new <strong>Student</strong></p>
        <br />
        <form className={classes.UserForm} onSubmit={(e) => sendAddUserHandler(e)}>
    
            <div className={classes.UserForm__Item}>
                <div>Given Name</div>
                <input value={props.addStudentObject.GivenName} type="text" required onChange={(e) => {onChangeHandler(e.target.value, "GivenName"); setFirstname(e.target.value)}} />
            </div>   
            <div className={classes.UserForm__Item}>
                <div>Surname</div>
                <input value={props.addStudentObject.Surname} type="text" required onChange={(e) => {onChangeHandler(e.target.value, "Surname"); setLastname(e.target.value)}} />
            </div> 
            <div className={classes.UserForm__Item}>
                <div>Display Name</div>
                <input disabled value={props.addStudentObject.DisplayName} />
            </div>                            
            {/* <div className={classes.UserForm__Item}>
                <div>Name</div>
                <input value={props.addStudentObject.Name} type="text" required onChange={(e) => onChangeHandler(e.target.value, "Name")} />
            </div> */}
            <div className={classes.UserForm__Item}>
                <div>Student ID</div>
                <input value={props.addStudentObject.StudentID} type="number" required onChange={(e) => onChangeHandler(e.target.value, "StudentID")} />
            </div>  
  
            <div className={classes.UserForm__Item}>
                <div>Programme Code</div>
                <select value={selectedProgrammeCode} required onChange={(e) => {onChangeHandler(e.target.value, "AcademicReportingCode"); onChangeHandler(e.target.value, "ProgrammeCode"); setSelectedProgrammeCode(e.target.value)}}>
                    <option value="" disabled>Select...</option>
                    {
                        programmeCode ?
                            programmeCode.map((item, index) => 
                                <option key={item.AcademicProgram_x002e_Name} value={item.RowKey}>{item.AcademicProgram_x002e_Name + " - (" + item.RowKey + ")"}</option>
                            ) 
                        : null
                    }
 
                </select>

            </div>
            <div className={classes.UserForm__Item}>
                <div>Student Category</div>
                <select value={props.addStudentObject.Category} required onChange={(e) => onChangeHandler(e.target.value, "Category")}>
                    <option value="" disabled>Select...</option>
                    <option value="Home">Home</option>
                    <option value="EU">EU</option>
                    <option value="Overseas">Overseas</option>
                </select>
            </div>
            <div className={classes.UserForm__Item}>
                <div>EAP Course (4 Weeks/8 Weeks)</div>
                <select value={props.addStudentObject.Course} required onChange={(e) => onChangeHandler(e.target.value, "Course")}>
                    <option value="" disabled>Select...</option>
                    <option value="(default)">(default)</option>
                    <option value="4">4</option>
                    <option value="8">8</option>
                </select>
            </div>  
            <div className={classes.UserForm__Item}>
                <div>Description</div>
                <input value={props.addStudentObject.Description} type="text" required onChange={(e) => onChangeHandler(e.target.value, "Description")} />
            </div>                                       
            <div className={classes.UserForm__Item}>
                <div>Start Date</div>
                <input type="date" required onChange={(e) => onChangeDateHandler(e.target.value, "StartMonth")} />
            </div>              
            <div className={classes.UserForm__Item}>
                <div>Expiry Date</div>
                <input type="date" onChange={(e) => onChangeDateHandler(e.target.value, "ExpiryDate")} />
            </div>              
            <div className={classes.UserForm__Item}>
                <div>Title</div>
                <input value={props.addStudentObject.JobTitle} type="text" required onChange={(e) => onChangeHandler(e.target.value, "JobTitle")} />
            </div>     
            <div className={classes.UserForm__Item}>
                <div>School</div>
                <input value={props.addStudentObject.Department} type="text" required onChange={(e) => onChangeHandler(e.target.value, "Department")} />
            </div> 
            <div className={classes.UserForm__Item}>
                <div>Office</div>
                <input value={props.addStudentObject.Office} type="text" required onChange={(e) => onChangeHandler(e.target.value, "Office")} />
            </div>             
            <div className={classes.UserForm__Item}>
                <div>Cohort</div>
                <input value={props.addStudentObject.City} type="city" required onChange={(e) => onChangeHandler(e.target.value, "City")} />
            </div>  
            <div className={classes.UserForm__Item}>
                <div>Personal Email</div>
                <input value={props.addStudentObject.PersonalEmail} type="email" required onChange={(e) => onChangeHandler(e.target.value, "PersonalEmail")} />
            </div>              
            <div className={classes.UserForm__Item}>
                <div>Phone Extension</div>
                <input value={props.addStudentObject.PhoneExtension} type="number" onChange={(e) => onChangeHandler(e.target.value, "PhoneExtension")} />
            </div>
            <Button type="submit">Add New Student</Button> 
            <div className={classes.UserForm__Message}>
                Please note: It can take up to 30 minutes for new users to be visible in the table.
            </div>                                                                                                     
        </form>
        </>
     
    )
}

export default AddStudent;