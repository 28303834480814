import React, { useEffect, useState, useMemo } from 'react';
import axios from '../../axios';

import Overlay from '../Overlay/Overlay';
import Button from '../Button/Button';
import SelectColumnFilter from '../Table/SelectColumnFilter';
import ScrollBars from 'react-custom-scrollbars';
import classes from './Blade.module.scss';
import Loader from '../Loader/Loader';
import CloseButton from '../CloseButton/CloseButton';
// import { PeoplePicker } from '@microsoft/mgt-react';
import PeoplePicker from '../PeoplePicker/PeoplePicker';
import moment from 'moment';
import Table from '../Table/Table';
import { CSVLink } from "react-csv";

const Blade = (props) => {

    const [ groupDetails, setGroupDetails ] = useState(null);
    const [ filterSettings ] = useState({ id: "displayName" });
    const [ selectedPeople, setSelectedPeople ] = useState([]);
    const [ csvLoader, setCsvLoader ] = useState(false);
    const [ csvData, setCsvData ] = useState(null);

    const closeBladeHandler = () => {
        props.setBladeOpen(false)
        setGroupDetails(null);
        props.setBlade(null)
    }

    const generateCSVHandler = () => {
        setCsvLoader(true);
        axios.put('/group-csv-data', {id: groupDetails.details.id})
        .then(res => { 
            const items = res.data.allResults.map(item => ({
                Email: item.userPrincipalName,
                Forename: item.givenName,
                Surname: item.surname,
                JobTitle: item.jobTitle,
                Cohort: item.city,
                Department: item.department
            }));
            setCsvData(items)
            setCsvLoader(false);

        })
        .catch(err => {console.log(err); setCsvLoader(false); })
    }


    const columns = useMemo(() => ([
        {
            Header: "Name",
            accessor: "displayName"
        },
        {
            Header: "Title",
            accessor: "jobTitle",          
        },
        {
            Header: "Department",
            accessor: "department",
            Filter: SelectColumnFilter,
            filter: 'includes',            
        },
        {
            Header: "Programme",
            accessor: "officeLocation",  
            Filter: SelectColumnFilter,
            filter: 'includes',                      
        },
        {
            Header: "Email",
            accessor: "mail",          
        },
        {
            Header: "Personal ID",
            accessor: "onPremisesSamAccountName"
        },
        {
            Header: "",
            accessor: "id",
            disableFilters: true,
            Cell: (x) => {
                if(props.userLevel === "admin"){
                    return(
                        <div onClick={() => removeGroupMemeberHandler(x.row.original)} title="Remove user from group" className={classes.Remove}>Remove</div>
                    )
                } else { 
                    return(
                        <div></div>
                    )
                }

                },
                width: 60
            }
    ]))


    const getGroupInfoHandler = () => {
        axios.post("/get-group-details", {displayName: props.data.GroupName})
        .then(res => {
            setGroupDetails(res.data)
        })
        .catch(err=> { 
            console.log(err)
        })        
    }

    useEffect(() => {
        if(props.data) {
            getGroupInfoHandler()
        } 
    }, [])

    const handleSelectionChanged = (data) => {
        setSelectedPeople(data)
    }


    const addGroupMemberHandler = () => {
        
        props.setMessage({
            type: "save",
            title: "Adding user",
            message: "Adding user to group, please wait."
        })

        if(props.data.Source === "On-Premises"){
            selectedPeople.forEach(item => {
                axios.post("/add-user-to-group", {
                    group: props.data.DistinguishedName,
                    email: item.userPrincipalName
                })
                .then(res => {
                    if(res.data.type === "error") {
                        props.setMessage({
                            type: "error",
                            title: "User not added",
                            message: "There was a problem adding this user."
                        })  
                    } else { 
                        props.setMessage({
                            type: "success",
                            title: "User(s) added",
                            message: "User(s) successfully added, please allow up to 30 minutes for these changes to sync."
                        })
                    }
                })
                .catch(err => {
                    props.setMessage({
                        type: "error",
                        title: "User not added",
                        message: "There was a problem adding this user."
                    })                    
                })
            })
        }

        if(props.data.Source === "Cloud"){
            selectedPeople.forEach(item => {
                axios.post('/add-user-to-group-graph', {
                    userId: item.id,
                    groupId: props.data.id
                })
                .then(res => {
                    if(res.data.type === "error") { 
                        props.setMessage({
                            type: "error",
                            title: "User not added",
                            message: "There was a problem adding this user."
                        })                          
                    } else { 
                        props.setMessage({
                            type: "success",
                            title: "User(s) added",
                            message: "User(s) successfully added."
                        })
                    }
                    getGroupInfoHandler()
                })
                .catch(err => {
                    props.setMessage({
                        type: "error",
                        title: "User not added",
                        message: "There was a problem adding this user."
                    })                    
                })
            })

        }
    }


    const removeGroupMemeberHandler = (user) => {
        props.setMessage({
            type: "save",
            title: "Removing user",
            message: "Removing user from group, please wait."
        })        
        if(props.data.Source === "On-Premises"){
            axios.post("/remove-user-from-group", {
                group: props.data.DistinguishedName,
                email: user.userPrincipalName
            })
            .then(res => {
                if(res.data.error === "error") { 
                    props.setMessage({
                        type: "error",
                        title: "User not added",
                        message: "There was a problem adding this user."
                    })                         

                } else { 
                    props.setMessage({
                        type: "success",
                        title: "User removed",
                        message: "User has been successfully removed, please allow up to 30 minutes for these changes to sync."
                    })
                }

            })
            .catch(err => {
                props.setMessage({
                    type: "error",
                    title: "User not added",
                    message: "There was a problem adding this user."
                })                    
            })        
        }

        if(props.data.Source === "Cloud") { 
            axios.post('/remove-user-from-group-graph', {
                userId: user.id,
                groupId: props.data.id
            })
            .then(res => {
                if(res.data.error === "error") { 
                    props.setMessage({
                        type: "error",
                        title: "User not added",
                        message: "There was a problem adding this user."
                    })  

                } else { 
                    props.setMessage({
                        type: "success",
                        title: "User removed",
                        message: "User successfully removed."
                    })                
                }
                getGroupInfoHandler()
            })
            .catch(err => {
                props.setMessage({
                    type: "error",
                    title: "User not added",
                    message: "There was a problem adding this user."
                })                    
            })
        }
    }

    return(
        <>
            { 
                props.bladeOpen ? 
                    <Overlay 
                        onClick={() => closeBladeHandler()}
                    />
                : null
            }
            {
                props.data ?
                    <section className={[classes.Blade, props.bladeOpen ? classes.Blade__Open : null].join(' ')}>
                        <CloseButton onClick={() => closeBladeHandler()} />
                            <div className={classes.Blade__Inner}>
                                {/* <ScrollBars renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}> */}
                                    {
                                        groupDetails?
                                        <div className={classes.Group}>
                                            <h1>{groupDetails.details.displayName}</h1>
                                            <small>Created on {moment(groupDetails.details.createdDateTime).format("DD/MM/YYYY HH:mm")}</small>
                                            {
                                                groupDetails.details.description ?
                                                    <p>{groupDetails.details.description}</p>
                                                : null
                                            }
                                            <div className={classes.Group__GroupManagement}>
                                                {
                                                    props.userLevel === "admin" ?
                                                    <>
                                                        <h2>Membership Management</h2>
                                                        <div className={classes.Group__Add}>
                                                            <label>Add Members</label>
                                                            <div>
                                                            {/* <PeoplePicker
                                                                selectionMode="multi"
                                                                selectionChanged={handleSelectionChanged} />  */}
                                                            <PeoplePicker 
                                                                noResultsMessage= "There were no results"
                                                                // groupIdSearch= "dac28f2f-08f6-4b41-aefa-135ebb100e0b"
                                                                apiRoute={process.env.REACT_APP_API_ROOT + "/people-search"}
                                                                multiSelect={true}
                                                                showSpinner={true}
                                                                selectedPeople={handleSelectionChanged}
                                                            />                
                                                            <br />                                              
                                                            <Button disabled={selectedPeople.length ? false : true} onClick={() => addGroupMemberHandler()}>Add users to group</Button>
                                                            </div>
                                                        </div>
                                                        </>
                                                    : null
                                                }
                                                {
                                                    groupDetails.members.length ?
                                                        <div className={classes.Group__Table}>
                                                        <h3>Current Members</h3>
                                                        <br />
                                                        <div className={classes.csv}>  
                                                            <Button onClick={() => generateCSVHandler()}>Generate CSV</Button>
                                                            {
                                                                csvLoader ?
                                                                    " Member list is being generated, please wait..."
                                                                : null
                                                            }
                                                            {
                                                                csvData ?
                                                                <>
                                                                &nbsp; Member list has been generated - <CSVLink data={csvData} title={"Download a CSV to show all members in this group, including members in sub groups"} filename={`${groupDetails.details.displayName}-members.csv`}>Download member list</CSVLink>
                                                                </>
                                                                :null
                                                            }
                                                        <br />
                                                        <br />
                                                        </div>

      
                                                        <Table 
                                                            columns={columns} 
                                                            data={groupDetails.members} 
                                                            rowsPerPage={30}
                                                            tableRowClickHandler={() => {}}
                                                            filterSettings={filterSettings}
                                                            hidePageOptions={true}/>
                
                                                        </div>
                                                    : <strong className={classes.NoMembers}>This group currently has no members</strong>
                                                } 

                                            </div>
                                        </div>
                                        : <Loader />
                                    }
                                {/* </ScrollBars> */}
                            </div>
                    </section>
                : null
            }

        </>
    )
}

export default Blade;