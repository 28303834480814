import React, { useMemo } from 'react';
import Button from '../Button/Button';
import axios from '../../axios';

const DisableUser = (props) => {

    const userObject = useMemo(() => (
        {
            "SamAccountName": props.selected.onPremisesSamAccountName,
            "UPN" : props.selected.userPrincipalName,
            "IsStaff": props.selected.personType === "Staff" ? true : false
        }
    ), [])


    const disableUserHandler = (e) => {
        e.preventDefault()

        props.setMessage({
            type: "save",
            title: "Processing",
            message: "This action is being processed."
        })

        axios.post(`/disable-user/${props.user.id}`, userObject)
        .then(res => {
            props.setMessage({
                type: "success",
                title: "Action Processed",
                message: "This action has been processed."
            })

            props.setDialogOpen(false)
        })
        .catch(err => {
            props.setMessage({
                type: "success",
                title: "Action Processed",
                message: "This action has been processed."
            })

            props.setDialogOpen(false)        
        })
    }

    return(
        <div>
            <p>Are you sure you to change the status of this user to be "Disabled?</p>
            <Button onClick={e => disableUserHandler(e)}>Disable This User</Button>
        </div>
    )
}

export default DisableUser;