import React, { useState, useEffect, useMemo } from 'react';
import axios from '../../axios';
import Table from '../Table/Table';
import classes from './Container.module.scss';
import SelectColumnFilter from '../Table/SelectColumnFilter';
import Blade from './Blade';
import Loader from '../Loader/Loader';

const GroupManagement = (props) => {

    const [ groups, setGroups ] = useState([]);
    const [ filterSettings ] = useState({ id: "GroupName" });
    const [ bladeOpen, setBladeOpen ] = useState(false);
    const [ blade, setBlade ] = useState(null);
    const [ loadingOnPrem, setLoadingOnPrem ] = useState(true);
    const [ loadingGraph, setLoadingGraph ] = useState(true)

    
    const columns = useMemo(() => ([
        {
            Header: "Name",
            accessor: "GroupName"
        },
        {
            Header: "Mail",
            accessor: "mail"
        },
        {
            Header: "Type",
            accessor: "PartitionKey",
            Filter: SelectColumnFilter,
            filter: 'includes',             
        },
        {
            Header: "Source",
            accessor: "Source",
            Filter: SelectColumnFilter,
            filter: 'includes',               
        }
    ]))
        
    //===================================================================
    // Get Groups
    //===================================================================
    const getOnPremGroupsHandler = () => {
        const partitions =  ["rcastaffadgroups", "rcastudentadgroups", "rcaalumniadgroups"]
        partitions.forEach(item => {
            axios.post('/get-groups-table-storage', { partitionKey: item })
            .then(res => {
                res.data.data.value.forEach(item => {
                    item.Source = "On-Premises"
                    // item.Mail = item.Mail ? item.Mail : "None"
                    item.mail = item.Mail ? item.Mail : "None"
                    item.PartitionKey = item.PartitionKey === "rcastaffadgroups" ? "Staff AD Groups" : item.PartitionKey === "rcaalumniadgroups" ? "Alumni AD Groups" : item.PartitionKey === "rcastudentadgroups" ? "Student AD Groups" : item.PartitionKey === "None"
                })
                
                setLoadingOnPrem(false)

                setGroups(prev => ([
                    ...prev,
                    ...res.data.data.value
                ]))                   

            })
            .catch(err => {
                console.log(err)
            })
        })        
    }

    const getGraphGroupsHandler = () => {
        axios.get(`/get-graph-groups`)
        .then(res => {
            res.data.data.forEach(item => {
                item.Source = "Cloud"
                item.PartitionKey = "Cloud Groups"
                item.GroupName = item.displayName
                item.mail = item.mail ? item.mail : "None"          
            })
            setGroups(prev => ([
                ...prev,
                ...res.data.data
            ]))
            setLoadingGraph(false)

        })
        .catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        getOnPremGroupsHandler()
        getGraphGroupsHandler()
    }, [])

    const tableRowClickHandler = (value) => {
        setBladeOpen(true)
        setBlade(value)     
    }

    return(
        <div className={classes.Container}>
            {
                loadingGraph || loadingOnPrem ?
                    <Loader />
                :
                <Table 
                    columns={columns} 
                    data={groups} 
                    rowsPerPage={30}
                    tableRowClickHandler={tableRowClickHandler}
                    filterSettings={filterSettings}/>
            }
            {
                blade ?
                    <Blade 
                        bladeOpen={bladeOpen}
                        setBladeOpen={setBladeOpen}
                        setBlade={setBlade}
                        data={blade}
                        userLevel={props.userLevel}
                        setDialogType={props.setDialogType}
                        setDialogOpen={props.setDialogOpen}   
                        setMessage={props.setMessage}                     
                    />
                : null                
            }                
        </div>
    )
}

export default GroupManagement;