import React from 'react';
import ScrollBars from 'react-custom-scrollbars';

import Overlay from '../Overlay/Overlay';
import classes from './Dialog.module.scss';
import CloseButton from '../CloseButton/CloseButton'

const Dialog = (props) => {

    return(
        <>
            <Overlay aboveBlade={props.aboveBlade} onClick={() => props.closeDialogHandler()}/>
            <div className={[classes.Dialog, props.dialogOpen ? classes.Dialog__Open : null].join(' ')}>
                <div className={classes.Dialog__Header}>
                    <div>{props.title}</div>
                    <CloseButton onClick={() => props.closeDialogHandler()} />
                </div>
                {/* <ScrollBars> */}
                    <div className={classes.Dialog__Inner}>
                        {props.children}
                    </div>
                {/* </ScrollBars> */}
            </div>
        </>
    )
}

export default Dialog;