import React, { useState, useEffect } from 'react';

import Loader from '../Loader/Loader';
import classes from './EditMemberships.module.scss';
import Button from '../Button/Button';

import axios from '../../axios';

const EditMemberships = (props) => {

    const [ groups, setGroups ] = useState(null);
    
    useEffect(() => {
        axios.get(`/groups/${props.user.id}`)
        .then((res) => {
            setGroups(res.data.data)
        })
        .catch((err) => console.log(err))
    }, [])

    const addItemHandler = (id) => {
        const match = groups.find(item => item.id === id);


        props.setMessage({
            type: "save",
            title: "Adding membership",
            message: "Please wait while user is added to new membership"
        })

        axios.post(`/add-membership/${props.user.id}`, {
            "StudentID": props.id,
            "Group": match.displayName
        })
        .then((res) => {

            props.setMemberships(prev => (
                [...prev, match]
            ));

            props.setMessage({
                type: "success",
                title: "Membership added",
                message: "User has been successfully added to this membership. Please allow up to 30 minutes for azure propagation"
            })
        })
        .catch(err => {
            props.setMessage({
                type: "error",
                title: "Membership not added",
                message: "There has been a problem and the user was not added to the membership. Please try again."
            })
        })
    }

    const removeItemHandler = (id) => {
        const memberships = props.memberships.filter(item => item.id !== id);
        const match = groups.find(item => item.id === id);


        props.setMessage({
            type: "save",
            title: "Removing membership",
            message: "Please wait while user is removed from the membership"
        })


        axios.post(`/remove-membership/${props.user.id}`, {
            "StudentID": props.id,
            "Group": match.displayName
        })
        .then(() => {
            props.setMemberships(memberships);

            props.setMessage({
                type: "success",
                title: "Membership removed",
                message: "User has been successfully removed from this membership. Please allow up to 30 minutes for azure propagation"
            }) 
        })
        .catch(err => {
            props.setMessage({
                type: "error",
                title: "Membership not removed",
                message: "There has been a problem and the user was not removed from the membership. Please try again."
            })
        })
    }    

    return(
        groups ?
            <div className={classes.Memberships}>
                <div className={classes.Memberships__Message}>
                    This table represents changes that will be made once propagation has taken place in Azure. Changes can take up to 30 minutes to become visible. Refreshing this page after making a change will likely bring back old data, if propagation hasn't taken place.
                </div>
                <div className={classes.Memberships__Inner}>
                    <div className={classes.Memberships__Col}>
                        {

                            groups.map((item, index) => {
                                const match = props.memberships.find(member => member.id === item.id)
                                if(!match){
                                    return(
                                        <div key={index} id={item.id} className={classes.Memberships__Item}>
                                            <span>{item.displayName}</span>
                                            <Button id={item.id} onClick={(e) => addItemHandler(e.target.id)}>Add</Button>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                    <div className={classes.Memberships__Col}>
                        {
                            props.memberships.map((item, index) => 
                                item.displayName !== null ?
                                    <div key={index} id={item.id} className={classes.Memberships__Item}>
                                        <span>{item.displayName}</span>
                                        <Button id={item.id} onClick={(e) => removeItemHandler(e.target.id)}>Remove</Button>
                                    </div>
                                : null
                            )
                        }
                    </div>            
                </div>
            </div>
        : <Loader />
    )
}

export default EditMemberships