import React, { useState, useEffect } from 'react';
import { HashRouter } from 'react-router-dom';
import { callMsGraph, callMsGraphOrg } from "../../graph";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

import Dashboard from '../Dashboard/Dashboard';

const Authenticated = (props) => {

    const [ user, setUser ] = useState({})

//     name: response.account.idToken.name,
// email: response.account.idToken.preferred_username,
// id: response.account.idToken.oid,
// tenant: response.account.idToken.tid

const { instance, accounts } = useMsal();

useEffect(() => {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
    }).then((response) => {
        callMsGraph(response.accessToken).then(response => {
            setUser(prev => ({
                ...prev,
                name: response.givenName,
                id: response.id,
                email: response.userPrincipalName
            }))
        });
    });

    instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
    }).then(response => {
        callMsGraphOrg(response.accessToken).then(response => {
            setUser(prev => ({
                ...prev,
                tenant: response.value[0].id
            }))
        });
    })
}, [])



    return(
        <HashRouter>
            <Dashboard 
                user={user} />
        </HashRouter>
    )
}

export default Authenticated;

