import React from 'react';
import Button from '../Button/Button';
import axios from '../../axios';

const WelcomeEmail = (props) => {

    const sendEmailsHandler = (e) => {
        e.preventDefault()

        props.setMessage({
            type: "save",
            title: "Processing",
            message: "This action is being processed."
        })

        axios.post(`/send-welcome-email/${props.user.id}`)
        .then(res => {
            props.setMessage({
                type: "success",
                title: "Action Processed",
                message: "This action has been processed."
            })

            props.setDialogOpen(false)
        })
        .catch(err => {
            props.setMessage({
                type: "error",
                title: "Action Not Processed",
                message: "There has been a problem processing this action. Please try again."
            })            
        })
    }

    return(
        <>
            <p>Clicking the below button will send out a welcome email to all new students.</p>
            <Button onClick={(e) => sendEmailsHandler(e)}>Send Welcome Emails</Button>
        </>
    )
}

export default WelcomeEmail;