import React, { useState } from 'react';

import Overlay from '../Overlay/Overlay';
import Button from '../Button/Button';
import ScrollBars from 'react-custom-scrollbars';
import classes from './Blade.module.scss';
import CloseButton from '../CloseButton/CloseButton';


const Blade = (props) => {

    const closeBladeHandler = () => {
        props.setBladeOpen(false)
        props.setSelected([])
    }

    const [ image, setImage ] = useState(null);
    const [ passwordDate, setPasswordDate ] = useState(null);

    // useEffect(() => {
    //     axios.post("/password-date", {id: props.selected.id})
    //     .then(res => {
    //         console.log(res.data)
    //     })
    //     .catch(err => console.log(err))
    // }, [props.selected])



    return(
        <>
            { 
                props.bladeOpen ? 
                    <Overlay 
                        onClick={() => closeBladeHandler()}
                    />
                : null
            }
            {
                props.data ?
                    <section className={[classes.Blade, props.bladeOpen ? classes.Blade__Open : null].join(' ')}>
                        <CloseButton onClick={() => closeBladeHandler()} />
                            <div className={classes.Blade__Inner}>
                        <ScrollBars renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}>
                                <div className={classes.Details}>
                                    <h2>User details:</h2>
                                    {
                                        props.data.details.map((item, index) => 
                                            item.data() ?
                                            <div className={classes.Details__Detail} key={index}>
                                                <div className={classes.Details__Label}>{item.label}</div>
                                                <div className={classes.Details__Data}>{item.data()}</div>
                                            </div>
                                            : null
                                        )

                                    }
                                    <div className={classes.Details__Buttons}>
                                        {
                                            props.userLevel === "admin" ?
                                            <>
                                            <Button onClick={() => {props.setDialogType("EditUser"); props.setDialogOpen(true)}}>Edit</Button>
                                            <Button onClick={() => {props.setDialogType("AddImage"); props.setDialogOpen(true)}}>Picture</Button>
                                            <Button onClick={() => {props.setDialogType("DisableUser"); props.setDialogOpen(true)}}>Close Account</Button>
                                            <Button onClick={() => {props.setDialogType("UserOnLeave"); props.setDialogOpen(true)}} disabled={props.selected.jobTitle ? props.selected.jobTitle.includes("(L)") : null}>User on Leave</Button>
                                            <Button onClick={() => {props.setDialogType("ChangePassword"); props.setDialogOpen(true)}}>Change Password</Button>
                                            <Button onClick={() => {props.setDialogType("Delegate"); props.setDialogOpen(true)}}>Add Delegate</Button>
                                            <Button onClick={() => {props.setDialogType("Alias"); props.setDialogOpen(true)}}>Add Alias</Button>
                                            </>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className={classes.Member}>
                                    <h2>{props.name} is a member of:</h2>
                                    { props.data.memberships() }
                                    {
                                        props.userLevel === "admin" ?
                                        <Button onClick={() => {props.setDialogType("Memberships"); props.setDialogOpen(true)}}>Edit Memberships</Button>
                                        : null
                                    }
                                </div>
                        </ScrollBars>
                            </div>
                    </section>
                : null
            }

        </>
    )
}

export default Blade;