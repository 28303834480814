import React from 'react'
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect,
  useFlexLayout,
} from 'react-table'
import matchSorter from 'match-sorter'
import classes from './Table.module.scss';
import ScrollBars from 'react-custom-scrollbars';
import Input from '../Input/Input';
import Select from '../Select/Select';
import Button from '../Button/Button';

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editable,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = e => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value)
  }

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  if (!editable) {
    return `${initialValue}`
  }

  return <Input value={value} onChange={onChange} onBlur={onBlur} />
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <Input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}



function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Be sure to pass our updateMyData and the skipReset option
function Table({ columns, data, updateMyData, skipReset, tableRowClickHandler, filterSettings, rowsPerPage, hidePageOptions }) {

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      // And also our default editable cell
      Cell: EditableCell,
    }),
    []
  )

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {
      pageIndex,
      pageSize,
      sortBy,
      groupBy,
      expanded,
      filters,
      selectedRowIds,
    },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
      useSortBy,
      // We also need to pass this so the page doesn't change
      // when we edit the data.
      autoResetPage: !skipReset,
      autoResetSelectedRows: !skipReset,
      autoResetFilters: !skipReset,
      disableMultiSort: true,
      initialState: {
        // Added this in to pass in the sort by settings
        sortBy: [
          {...filterSettings}
        ],
        pageSize: rowsPerPage
    }        
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useFlexLayout,
    // Here we will use a plugin to add our selection column
    // hooks => {
    //   hooks.visibleColumns.push(columns => {
    //     return [
    //       {
    //         id: 'selection',
    //         // Make this column a groupByBoundary. This ensures that groupBy columns
    //         // are placed after it
    //         groupByBoundary: true,
    //         // The header can use the table's getToggleAllRowsSelectedProps method
    //         // to render a checkbox
    //         Header: ({ getToggleAllRowsSelectedProps }) => (
    //           <div>
    //             <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    //           </div>
    //         ),
    //         // The cell can use the individual row's getToggleRowSelectedProps method
    //         // to the render a checkbox
    //         Cell: ({ row }) => (
    //           <div>
    //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //           </div>
    //         ),
    //       },
    //       ...columns,
    //     ]
    //   })
    // }
  )

  // Render the UI for your table
  return (
    <>
      <div {...getTableProps()} className={classes.Table}>
        <div className={classes.Thead}>
          {headerGroups.map(headerGroup => (
            <div className={classes.Tr} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <div className={classes.Th} {...column.getHeaderProps()}>
                  <div>
                    {/* {column.canGroupBy ? (
                      // If the column can be grouped, let's add a toggle
                      <span {...column.getGroupByToggleProps()}>
                        {column.isGrouped ? '🛑 ' : '👊 '}
                      </span>
                    ) : null} */}
                    <span {...column.getSortByToggleProps()}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </div>
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className={classes.Tbody} {...getTableBodyProps()}>
          <ScrollBars>
          {page.map(row => {
            prepareRow(row)
            return (
              <div className={classes.Tr} {...row.getRowProps()} onClick={(e) => tableRowClickHandler(row.original, e)}>
                {row.cells.map(cell => {
                  return (
                    <div className={classes.Td} {...cell.getCellProps()}>
                      {cell.isGrouped ? (
                        // If it's a grouped cell, add an expander and row count
                        <>
                          <span {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ? '👇' : '👉'}
                          </span>{' '}
                          {cell.render('Cell', { editable: false })} (
                          {row.subRows.length})
                        </>
                      ) : cell.isAggregated ? (
                        // If the cell is aggregated, use the Aggregated
                        // renderer for cell
                        cell.render('Aggregated')
                      ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                        // Otherwise, just render the regular cell
                        cell.render('Cell', { editable: false })
                      )}
                    </div>
                  )
                })}
              </div>
            )
          })}
          </ScrollBars>
        </div>
      </div>
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
<div className={classes.Pagination}>

          
<Button title="Go to the first page" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
  {"<< Start"}
</Button>
<Button title="Back to the previous page" onClick={() => previousPage()} disabled={!canPreviousPage}>
  {'< Previous'}
</Button>

{
  ! hidePageOptions ?
  <div className={classes.Pagination__NavOptions}>
  { 
    // scrollable ?
    <Select
    value={pageSize}
    onChange={e => {
      setPageSize(Number(e.target.value))
    }}>
{[10, 20, 30, 40, 50].map(pageSize => (
  <option key={pageSize} value={pageSize}>
    Show {pageSize} rows per page
  </option>
))}
</Select>  
    // : null
  }
  <span>Page</span>
  <Input
  type="number"
  defaultValue={pageIndex + 1}
  onChange={e => {
    const page = e.target.value ? Number(e.target.value) - 1 : 0
    gotoPage(page)
  }}
  style={{ width: '100px' }}
/>
<span>of</span>   
<span>{pageOptions.length}</span>       
<div></div>    
</div>
: null
}

<Button title="To the next page" onClick={() => nextPage()} disabled={!canNextPage}>
  {'Next >'}
</Button>
<Button title="Go to the last page" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
  {'End >>'}
</Button>

</div>
      {/* <pre>
        <code>
          {JSON.stringify(
            {
              pageIndex,
              pageSize,
              pageCount,
              canNextPage,
              canPreviousPage,
              sortBy,
              groupBy,
              expanded: expanded,
              filters,
              selectedRowIds: selectedRowIds,
            },
            null,
            2
          )}
        </code>
      </pre> */}
    </>
  )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

// This is a custom aggregator that
// takes in an array of leaf values and
// returns the rounded median
function roundedMedian(leafValues) {
  let min = leafValues[0] || 0
  let max = leafValues[0] || 0

  leafValues.forEach(value => {
    min = Math.min(min, value)
    max = Math.max(max, value)
  })

  return Math.round((min + max) / 2)
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <Input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

// function App(props) {

//     console.log(props.columns)
// //   const [data, setData] = React.useState(() => makeData(10000))
// //   const [originalData] = React.useState(data)


//   // We need to keep the table from resetting the pageIndex when we
//   // Update data. So we can keep track of that flag with a ref.
//   // const skipResetRef = React.useRef(false)

//   // // When our cell renderer calls updateMyData, we'll use
//   // // the rowIndex, columnId and new value to update the
//   // // original data
//   // const updateMyData = (rowIndex, columnId, value) => {
//   //   // We also turn on the flag to not reset the page
//   //   skipResetRef.current = true
//   //   // setData(old =>
//   //   //   old.map((row, index) => {
//   //   //     if (index === rowIndex) {
//   //   //       return {
//   //   //         ...row,
//   //   //         [columnId]: value,
//   //   //       }
//   //   //     }
//   //   //     return row
//   //   //   })
//   //   // )
//   // }

//   // After data changes, we turn the flag back off
//   // so that if data actually changes when we're not
//   // editing it, the page is reset
//   // React.useEffect(() => {
//   //   skipResetRef.current = false
//   // }, [props.data])


//   // return (
//   //     props.data ?
//   //       <Table
//   //           columns={props.columns}
//   //           data={props.data}
//   //           //updateMyData={updateMyData}
//   //           //skipReset={skipResetRef.current}
//   //           skipReset={props.skipReset}
//   //           tableRowClickHandler={props.tableRowClickHandler}
//   //           filterSettings={props.filterSettings}
//   //       />
//   //     : null
//   // )
// }

export default Table
