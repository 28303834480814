import React, { useEffect, useState, useMemo } from 'react';
import axios from '../../axios';
import { Route, NavLink } from 'react-router-dom';
import moment from 'moment';


import classes from './Container.module.scss';
import Table from '../Table/Table';
import SelectColumnFilter from '../Table/SelectColumnFilter';
import Loader from '../Loader/Loader';
import Blade from '../Blade/Blade';
import Dialog from '../Dialog/Dialog';
import EditUser from '../UserActions/EditUser';
import EditMemberships from '../EditMemberships/EditMemberships';
import Header from '../Header/Header';
import AddUser from '../UserActions/AddUser';
import AddImage from '../AddImage/AddImage';
import WelcomeEmail from '../WelcomeEmail/WelcomeEmail';
import DisableUser from '../DisableUser/DisableUser';
import UserOnLeave from '../UserOnLeave/UserOnLeave';
import CSVUpload from '../CSVUpload/CSVUpload';
import GroupManagement from '../GroupManagement/Container';
import ChangePassword from '../ChangePassword/ChangePassword';
import Delegate from '../Delegate/Delegate';
import Alias from '../Alias/Alias';
import YourPhoto from '../StudentContainer/StudentContainer';



const Container = (props) => {
    // ==================================================================================================
    // = Get Students and Staff
    // ==================================================================================================
    const [ people, setPeople ] = useState([]);
    const [ staffDone, setStaffDone ] = useState(false);
    const [ studentsDone, setStudentsDone ] = useState(false);
    const [ graduatesDone, setGraduatesDone ] = useState(false);
    

    useEffect(() => {
        axios.get(`/staff/${props.user.id}`)
        .then(res => {
            const staff = res.data.data
            staff.forEach(item => {
                item["personType"] = "Staff"
                if(item.jobTitle === null) {
                    item.jobTitle = "Unset"
                }
                if(item.officeLocation === null) {
                    item.officeLocation = "Unset"
                }
                if(item.department === null) {
                    item.department = "Unset"
                }

                if(item.extension_79691cca0d96446486f318046d831b9a_employeeNumber) { 
                    item.ID = item.extension_79691cca0d96446486f318046d831b9a_employeeNumber
                } else { 
                    item.ID = "Unset"
                }
         
                if(item.otherMails.length !== 0) {
                    item.otherMails = item.otherMails[0]
                } else { 
                    item.otherMails = undefined
                }
                if(item.businessPhones.length !== 0) {
                    item.businessPhones = item.businessPhones[0]
                } else { 
                    item.businessPhones = undefined
                }               
                
            })
            setPeople(prev => [...prev, ...staff])
            setStaffDone(true)
        })

        .catch(err => console.log(err))
            
        axios.get(`/students/${props.user.id}`)
        .then(res => {
            const students = res.data.data
            students.forEach(item => {
                item["personType"] = "Student"
                if(item.jobTitle === null) {
                    item.jobTitle = "Unset"
                }
                if(item.officeLocation === null) {
                    item.officeLocation = "Unset"
                }
                if(item.department === null) {
                    item.department = "Unset"
                } 
                
                if(item.extension_79691cca0d96446486f318046d831b9a_uid) { 
                    item.ID = item.extension_79691cca0d96446486f318046d831b9a_uid 
                } else { 
                    item.ID = "Unset"
                }
                if(item.otherMails.length !== 0) {
                    item.otherMails = item.otherMails[0]
                } else { 
                    item.otherMails = undefined
                }
                if(item.businessPhones.length !== 0) {
                    item.businessPhones = item.businessPhones[0]
                } else { 
                    item.businessPhones = undefined
                }                                                   
            })
            setPeople(prev => [...prev, ...students])
            setStudentsDone(true)
        })
        .catch(err => console.log(err))        
        
        axios.get(`/graduates/${props.user.id}`)
        .then(res => {
            const graduates = res.data.data
            graduates.forEach(item => {
                item["personType"] = "Student"
                if(item.jobTitle === null) {
                    item.jobTitle = "Unset"
                }
                if(item.officeLocation === null) {
                    item.officeLocation = "Unset"
                }
                if(item.department === null) {
                    item.department = "Unset"
                }  
                if(item.extension_79691cca0d96446486f318046d831b9a_uid) { 
                    item.ID = item.extension_79691cca0d96446486f318046d831b9a_uid 
                } else { 
                    item.ID = "Unset"
                }                
                if(item.otherMails.length !== 0) {
                    item.otherMails = item.otherMails[0]
                } else { 
                    item.otherMails = undefined
                }
                if(item.businessPhones.length !== 0) {
                    item.businessPhones = item.businessPhones[0]
                } else { 
                    item.businessPhones = undefined
                }                                                   
            })
            setPeople(prev => [...prev, ...graduates])
            setGraduatesDone(true)
        })
        .catch(err => console.log(err))        
        
    }, [])


    // ==================================================================================================
    // = Table Columns
    // ==================================================================================================
    const columns = useMemo(() => [
        {
            Header: "ID",
            accessor: "ID"
        },
        {
            Header: "First Name",
            accessor: "givenName",
        },
        {
            Header: "Surname",
            accessor: "surname",
        },
        {
            Header: "Extension",
            accessor: "businessPhones"
        },
        {
            Header: "Job Title",
            accessor: "jobTitle",
            Filter: SelectColumnFilter,
            filter: 'includes',            
        },
        {
            Header: "Department",
            accessor: "department",
            Filter: SelectColumnFilter,
            filter: 'includes',
        },
        // {
        //     Header: "Directorate",
        //     accessor: "department",
        //     Filter: SelectColumnFilter,
        //     filter: 'includes',            
        // },
        {
            Header: "Email",
            accessor: "mail",
            width: 200
        },
        {
            Header: "Role",
            accessor: "personType",
            Filter: SelectColumnFilter,
            filter: 'includes',
            width: 80
        }
            
    ], []);

    const filterSettings = {
        id: "givenName"
    }

    // ==================================================================================================
    // = Selected Row
    // ==================================================================================================
    const [ selected, setSelected ] = useState(null);
    
    // ==================================================================================================
    // = Add Staff
    // ==================================================================================================
    const [ addStaffObject, setAddStaffObject ] = useState(
        {
            "AcademicReportingCode":"",
            "Category":"",
            "City":"",
            "Course":"",
            "Department":"",
            "Description": moment().format("YYYY"),
            "DisplayName": "",
            "ExpiryDate":"",
            "GivenName": "",
            "JobTitle":"",
            "Name": "",
            "Office":"",
            "PersonalEmail":"",
            "PhoneExtension":"",
            "ProgrammeCode":"",
            "StartMonth":"01/01/2020",
            "StudentID": "",
            "HESAID": "",
            "Surname": "",
            "AdminDescription": "",
            "CreateMailbox": false
          }
    );


    // ==================================================================================================
    // = Add Student
    // ==================================================================================================
    const [ addStudentObject, setAddStudentObject ] = useState(
        {
            "DisplayName": "",
            "GivenName": "",
            "Name": "",
            "StudentID": "",
            "Surname": "",
            "ProgrammeCode":"",
            "PersonalEmail":"",
            "JobTitle": "",
            "Department":"",
            "City":"",
            "PhoneExtension":"",
            "AcademicReportingCode":"",
            "Category":"",
            "Course":"",
            "Description": moment().format("YYYY"),
            "ExpiryDate":"",
            "Office":"",
            "StartMonth":"",
            "Bulk": "N"
          }        
    );     
  
    // ==================================================================================================
    // = Dialog
    // ==================================================================================================
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ dialogType, setDialogType ] = useState(null)

    const closeDialogHandler = () => {
        setDialogOpen(false)
        setDialogType(null)
        setAddStaffObject({
            "AcademicReportingCode":"",
            "Category":"",
            "City":"",
            "Course":"",
            "Department":"",
            "Description": moment().format("YYYY"),
            "DisplayName": "",
            "ExpiryDate":"",
            "GivenName": "",
            "JobTitle":"",
            "Name": "",
            "Office":"",
            "PersonalEmail":"",
            "PhoneExtension":"",
            "ProgrammeCode":"",
            "StudentID": "",
            "Surname": "",
            "AdminDescription": "",
            "CreateMailbox": false
        })
        setAddStudentObject({
            "DisplayName": "",
            "GivenName": "",
            "Name": "",
            "StudentID": "",
            "Surname": "",
            "ProgrammeCode":"",
            "PersonalEmail":"",
            "JobTitle": "",
            "Department":"",
            "City":"",
            "PhoneExtension":"",
            "AcademicReportingCode":"",
            "Category":"",
            "Course":"",
            "Description": moment().format("YYYY"),
            "ExpiryDate":"",
            "Office":"",
            "StartMonth":"",
            "Bulk": "N"
          })        
    }

    // ==================================================================================================
    // = Get Memberships
    // ==================================================================================================
    const [ memberships, setMemberships ] = useState(null);
    const getMembershipsHandler = (id) => {
        axios.get(`/memberships/${props.user.id},${id}`)
        .then((res) => {
            setMemberships(res.data.data)
        })
        .catch((err) => console.log(err))
    }

    useEffect(() => {
        if(selected){
            if(selected.id){
                getMembershipsHandler(selected.id)
            }
        }
    }, [selected])



    // ==================================================================================================
    // = Blade
    // ==================================================================================================
    const [ bladeOpen, setBladeOpen ] = useState(false);

    const blade = useMemo(() => {
        if(selected) {
            return(
                {
                    details: [
                        {
                            label: "Name",
                            data: () => selected.displayName,
                        },
                        {
                            label: "Title",
                            data: () => selected.jobTitle,
                        },
                        {
                            label: "Programme",
                            data: () => selected.officeLocation,
                        },
                        {
                            label: "Email",
                            data: () => <a href={`mailto:${selected.mail}`}>{selected.mail}</a>
                        },
                        {
                            label: "Personal Email",
                            data: () => props.userLevel === "admin" ? <a href={`mailto:${selected.otherMails}`}>{selected.otherMails}</a> : null                    
                        },
                        {
                            label: "userPrincipalName",
                            data: () => selected.userPrincipalName
                        },
                        {
                            label: selected.personType === "Staff" ? "Employee ID" : "Student ID",
                            data: () => selected.personType === "Staff" ? selected.extension_79691cca0d96446486f318046d831b9a_employeeNumber : selected.extension_79691cca0d96446486f318046d831b9a_uid 
                        },
                        {
                            label: "Password Last Changed",
                            data: () =>  moment(selected.lastPasswordChangeDateTime).format("YYYY") > 1800 ? moment(selected.lastPasswordChangeDateTime).format("DD-MM-YYYY HH:mm") : "User must set new password"
                        }
                    ],
                    memberships: () => (
                        memberships ? 
                            <div className={classes.Memberships}>
                                {
                                    memberships.map(item => 
                                        item.displayName !== null ?
                                            <div className={classes.Memberships__Membership} key={item.id} id={item.id}>
                                                <div className={classes.Memberships__Name}>{item.displayName}</div>
                                                <div className={classes.Memberships__Description}>{item.description}</div>
                                            </div>
                                        : null
                                    ) 
                                }
                            </div>
                        : <Loader />
                    )
                            
                }
            )
        } else { 
            return null
        }
    }, [selected, memberships])    


    // ==================================================================================================
    // = Row Click Handler
    // ==================================================================================================
    const tableRowClickHandler = (data) => {
        setMemberships(null)
        setSelected(data)
        setBladeOpen(true)
    }



    return(
        <>

        <Header 
            title={"People Directory"} 
            setDialogOpen={setDialogOpen} 
            setDialogType={setDialogType}  
            options={
                [
                    <div><NavLink exact to="/">Home</NavLink></div>,
                    <div><NavLink exact to="/group-management">Group Management</NavLink></div>,
                    props.userLevel === "admin" ?
                    <div onClick={() => {setDialogType("AddUser"); setDialogOpen(true)}}>Add New User</div>
                    : null,
                    props.userLevel === "admin" ?
                    <div onClick={() => {setDialogType("WelcomeEmail"); setDialogOpen(true)}}>Send Welcome Emails</div>
                    : null,
                    props.userLevel === "admin" ?
                    <div onClick={() => {setDialogType("CSVUpload"); setDialogOpen(true)}}>Google Sheets Upload &amp; Process</div>
                    : null,
                    <div><NavLink exact to="/your-photo">Your Photo</NavLink></div>
                ]
            } />

            <Route exact path="/">
            {
                staffDone && studentsDone && graduatesDone ?
                    <section className={classes.Container}>
                        <Table 
                            columns={columns} 
                            data={people} 
                            rowsPerPage={30}
                            tableRowClickHandler={tableRowClickHandler}
                            filterSettings={filterSettings}/>
                        {
                            blade && selected ?
                                <Blade 
                                    bladeOpen={bladeOpen}
                                    setBladeOpen={setBladeOpen}
                                    selected={selected}
                                    setSelected={setSelected}
                                    data={blade}
                                    name={selected.displayName}
                                    setDialogType={setDialogType}
                                    setDialogOpen={setDialogOpen}
                                    userLevel={props.userLevel}
                                />
                            : null
                        }
                        
                    </section>
                : <Loader />
            }   
            </Route>

            <Route exact path="/your-photo">
                <YourPhoto 
                    hideHeader={true} 
                    setMessage={props.setMessage} 
                    user={props.user} />
            </Route>

            <Route exact path="/group-management">
                <GroupManagement 
                    user={props.user}
                    userLevel={props.userLevel}
                    setMessage={props.setMessage}
                    setDialogType={setDialogType}
                    setDialogOpen={setDialogOpen} />
            </Route>

            {
                            dialogOpen ?
                                <Dialog 
                                    dialogOpen={dialogOpen} 
                                    closeDialogHandler={closeDialogHandler}
                                    showOverlay={dialogType === "AddUser" ? true : false}
                                    setAddStaffObject={setAddStaffObject}
                                    setAddStudentObject={setAddStudentObject}
                                    title={dialogType === "AddUser" ? "Add new user" : dialogType === "EditUser" ? "Edit user details" : dialogType === "Memberships" ? "Edit memberships" : dialogType === "AddImage" ? "User Image" : dialogType === "WelcomeEmail" ? "Send Welcome Emails" : dialogType === "DisableUser" ? "Disable User" : dialogType === "UserOnLeave" ? "Set User On Leave of Absense" : dialogType === "CSVUpload" ? "Google Sheets Upload & Process" : dialogType === "ChangePassword" ? "Change Password" : dialogType === "UserRemoved" ? "User Removed" : dialogType === "Delegate" ? "Add Delegate" : dialogType === "Alias" ? "Add Alias" : null }
                                    aboveBlade={true}>
                                    {
                                        dialogType === "EditUser" ?
                                            <EditUser 
                                                selected={selected} 
                                                setMessage={props.setMessage}
                                                setDialogOpen={setDialogOpen} 
                                                user={props.user}/>
                                        : dialogType === "Memberships" ?
                                            <EditMemberships 
                                                memberships={memberships}
                                                setMemberships={setMemberships}
                                                id={selected.onPremisesSamAccountName} 
                                                setMessage={props.setMessage} 
                                                user={props.user}/>
                                        : dialogType === "AddUser" ?
                                            <AddUser 
                                                user={props.user}
                                                setMessage={props.setMessage} 
                                                setAddStaffObject={setAddStaffObject}
                                                addStaffObject={addStaffObject} 
                                                addStudentObject={addStudentObject}
                                                setAddStudentObject={setAddStudentObject}
                                                closeDialogHandler={closeDialogHandler}
                                                />
                                        : dialogType === "AddImage" ?
                                            <AddImage
                                                id={selected.onPremisesSamAccountName} 
                                                setMessage={props.setMessage} 
                                                setDialogOpen={setDialogOpen} />
                                        : dialogType === "WelcomeEmail" ?
                                            <WelcomeEmail 
                                                setMessage={props.setMessage}
                                                setDialogOpen={setDialogOpen} 
                                                user={props.user}/>
                                        : dialogType === "DisableUser" ?
                                            <DisableUser 
                                                setMessage={props.setMessage}
                                                selected={selected}
                                                setDialogOpen={setDialogOpen}
                                                user={props.user}/>
                                        : dialogType === "UserOnLeave" ?
                                            <UserOnLeave
                                                setMessage={props.setMessage}
                                                selected={selected}
                                                setDialogOpen={setDialogOpen}
                                                user={props.user}/>
                                        : dialogType === "CSVUpload" ?
                                            <CSVUpload 
                                                setMessage={props.setMessage}
                                                user={props.user}/>
                                        : dialogType === "ChangePassword" ?
                                            <ChangePassword 
                                                user={props.user}
                                                setMessage={props.setMessage} 
                                                selected={selected} 
                                                people={people}
                                                setPeople={setPeople}/>
                                        : dialogType === "userRemoved" ?
                                            <div>
                                                <p>User has been removed. Please allow up to 30 minutes for changes to be reflected</p>
                                            </div>
                                        : dialogType === "Delegate" ?
                                            <Delegate 
                                                user={props.user}
                                                selected={selected} 
                                                setMessage={props.setMessage} />
                                        : dialogType === "Alias" ?
                                            <Alias 
                                                user={props.user}
                                                selected={selected} 
                                                setMessage={props.setMessage} />
                                        : null
                                    }
                                </Dialog>
                            : null
                        }

        </>
    )
}

export default Container;