import React from 'react';

import classes from './Overlay.module.scss';

const Overlay = (props) => {

    return(
        <div className={classes.Overlay} onClick={props.onClick ? () => props.onClick() : null} style={props.aboveBlade ? {zIndex: "9998"} : null}></div>
    )
}

export default Overlay;