import React, { useState } from 'react';
import Button from '../../components/Button/Button';
import axios from '../../axios';


const Alias = (props) => {

    const [ alias, setAlias ] = useState("");
    const [ friendlyName, setFriendlyName ] = useState("")

    const setAliasHandler = () => {
        props.setMessage({
            type: "save",
            title: "Adding Alias",
            message: "Please wait while alias is added"
        });
        axios.post("/alias", { 
            "UserEmail": props.selected.userPrincipalName,
            "NewAddress" : alias + "@" + props.selected.userPrincipalName.split("@")[1],
            "AliasFriendlyName": friendlyName,
            "type": props.selected.personType
        })
        .then(res => {
            console.log(res)
            if(typeof res.data.alias === "object"){
                props.setMessage({
                    type: "success",
                    title: "Alias Added",
                    message: "Alias was successfully added"
                });
            } else { 
                props.setMessage({
                    type: "error",
                    title: "Alias not added",
                    message: "There was a problem adding this alias. Please make sure the email address is valid."
                });     
            }
        })
        .catch(err => {
            console.log(err)
            props.setMessage({
                type: "error",
                title: "Alias not added",
                message: "There was a problem adding this alias. Please make sure the email address is valid."
            });    
        })
    }

    return(
        <>

 
            <label>Friendly Name</label>
            <br />
            <input type="text" value={friendlyName} onChange={(e) => setFriendlyName(e.target.value) }/>
            <br />
            <br />
            <label>Alias</label>
            <br />
            <input type="text" value={alias} onChange={(e) => setAlias(e.target.value)} />@{ props.selected.userPrincipalName.split("@")[1]}
            <br /><br />
            <Button disabled={alias === "" || friendlyName === "" ? true : false} onClick={() => setAliasHandler()}>Add Alias</Button>
            <br />
        </>
    )
}

export default Alias;