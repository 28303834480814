import React from 'react';
import { ReactComponent as CloseIcon } from  '../../images/icon-cross.svg';

import classes from './CloseButton.module.scss';

const CloseButton = (props) => {

    return(
        <div className={classes.Close} onClick={() => props.onClick() }>
            <CloseIcon />
        </div>
    )
}

export default CloseButton;