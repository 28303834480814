import React, { useState } from 'react';
import Button from '../Button/Button';
import axios from '../../axios';
import moment from 'moment';

const CSVUpload = (props) => {

    const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"))

    const cvsButtonLinkHandler = (e, link) => { 
        e.preventDefault();
        window.open(link, '_blank')
    }

    const processingMessageHandler = () => {
        props.setMessage({
            type: "save",
            title: "Processing",
            message: "This action is being processed."
        })
    }

    const successMessageHandler = () => {
        props.setMessage({
            type: "success",
            title: "Action Processed",
            message: "This action has been processed."
        })
    }

    const errorMessageHandler = () => {
        props.setMessage({
            type: "error",
            title: "Action Not Processed",
            message: "There has been a problem processing this action. Please try again."
        })         
    }

    const processActionHandler = (e, path, object) => {
        e.preventDefault()
        processingMessageHandler()
        axios.post(path, object ? object : null)
        .then(res => {
            successMessageHandler()
        })
        .catch(err => {console.log(err); errorMessageHandler()})
    }

    return(
        <div>
            <p>Upload your files first</p>
            <Button onClick={(e) => cvsButtonLinkHandler(e, 'https://docs.google.com')}>Upload your files</Button>
            <div style={{"height": "1px", "width": "100%", "background": "rgba(0,0,0,0.2)", "margin": "20px 0"}} />            
            <p>
                <br />
                <strong>New Student Account Locations/Groups</strong>
                <br/>
            </p>
            <input value={selectedYear} onChange={e => setSelectedYear(e.target.value)} type="number" step="1" />
            <Button onClick={(e) => processActionHandler(e, '/import-student-groups', {year: selectedYear})}>Process</Button>
            <br />
            <div style={{"height": "1px", "width": "100%", "background": "rgba(0,0,0,0.2)", "margin": "20px 0"}} />
            <p>
                <strong>New Staff Account Locations</strong>
                <br />
            </p>
            <Button onClick={(e) => processActionHandler(e, '/import-staff-groups')}>Process</Button>                  
            <div style={{"height": "1px", "width": "100%", "background": "rgba(0,0,0,0.2)", "margin": "20px 0"}} />
            <p>
                <strong>New Students</strong>
                <br/>
            </p>
            <Button onClick={(e) => processActionHandler(e, '/bulk-student-import')}>Process</Button>
            <br />
      
        </div>
    )
}

export default CSVUpload;