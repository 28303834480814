import React, { useState } from 'react';
// import { PeoplePicker } from '@microsoft/mgt-react';
import PeoplePicker from '../PeoplePicker/PeoplePicker';
import Button from '../../components/Button/Button';
import axios from '../../axios';


const Delegate = (props) => {

    const [ delegate, setDelegate ] = useState([]);

    const handleSelectionChanged = (data) => {
        setDelegate(data)
    }

    const setDelegateHandler = () => {
        props.setMessage({
            type: "save",
            title: "Adding Delegate",
            message: "Please wait while delegate is added"
        });
        axios.post("/delegate", { 
            "SharedEmail": props.selected.userPrincipalName,
            "UserEmail" : delegate[0].userPrincipalName,
            "type": props.selected.personType
        })
        .then(res => {
            if(typeof res.data.delegate === "object"){

            props.setMessage({
                type: "success",
                title: "Delegate Added",
                message: "Delegate was successfully added"
            });
        } else { 
            props.setMessage({
                type: "error",
                title: "Delegate not added",
                message: "There was a problem adding this delegate, please make sure the account is enabled and different."
            });
        }
        })
        .catch(err => {
            console.log(err)
            props.setMessage({
                type: "error",
                title: "Delegate not added",
                message: "There was a problem adding this delegate, please make sure the account is enabled and different."
            });            
        })
    }

    return(
        <>
            {/* <PeoplePicker
            selectionMode="single"
            selectionChanged={handleSelectionChanged} />  */}

                <PeoplePicker 
                    noResultsMessage= "There were no results"
                    // groupIdSearch= "dac28f2f-08f6-4b41-aefa-135ebb100e0b"
                    apiRoute={process.env.REACT_APP_API_ROOT + "/people-search"}
                    multiSelect={false}
                    showSpinner={true}
                    selectedPeople={handleSelectionChanged}
                />
            {
                delegate.length ? (
                    <>
                        <br /><br />
                        <div>Selected delegate: <strong>{delegate[0].userPrincipalName}</strong></div>
                    </>
                ) : null
            }
            <br /><br />
            <Button disabled={delegate === null ? true : false} onClick={() => setDelegateHandler()}>Add Delegate</Button>
            <br />
        </>
    )
}

export default Delegate;