import React, { useState, useEffect } from 'react';
import axios from '../../axios';
import moment from 'moment';

import classes from './UserForm.module.scss';
import Button from '../Button/Button';

const EditStaff = (props) => {

    const [ originalSelected, setOriginalSelected ] = useState({...props.selected, StaffID: props.selected.extension_79691cca0d96446486f318046d831b9a_employeeNumber, HESAID: props.selected.employeeId, ContractType: props.selected.extension_79691cca0d96446486f318046d831b9a_adminDescription});
    const [firstname, setFirstname] =  useState(originalSelected.givenName);
    const [lastname, setLastname] = useState(originalSelected.surname);
    const [ programmeCode, setProgrammeCode] = useState(null);
    const [ selectedProgrammeCode, setSelectedProgrammeCode ] = useState(null);

    useEffect(() => {
        axios.post("get-user-creation-table-stroage", {partitionKey: "staffassignedgroups", table: "RCAStaffGroupAssignments"})
        .then(res => setProgrammeCode(res.data.data.value))
        .catch(err => console.log(err))
    }, [])
    
    useEffect(() => {
        if(selectedProgrammeCode){
            const foundObject = programmeCode.find(item => item.Selection === selectedProgrammeCode)
            onChangeHandler(foundObject.AD_Department, "department")
            onChangeHandler(foundObject.AD_Office, "officeLocation")
        }
    }, [selectedProgrammeCode])    

    const onChangeHandler = (value, target) => {
        setOriginalSelected(prev => (
            {
                ...prev,
                [target]: value
            }
        ))
    }

    useEffect(() => {
        setOriginalSelected(prev => (
            {
                ...prev,
                displayName: firstname + " " + lastname
            }
        ))
    }, [lastname, firstname])    

    const sendEditUserHandler = (e) => {
        e.preventDefault();

        props.setMessage({
            type: "save",
            title: "Updating user information",
            message: "User information is being saved, please wait"
        })

        const editUserObject = {
            "Surname": originalSelected.surname,
            "StudentID": originalSelected.onPremisesSamAccountName,
            "ProgrammeCode": originalSelected.companyName,
            "PersonalEmail": originalSelected.otherMails,
            "JobTitle": originalSelected.jobTitle,
            "GivenName": originalSelected.givenName,
            "DisplayName": originalSelected.displayName,
            "Department": originalSelected.department,
            "City": "",
            // "Description": originalSelected.description,
            "ExpiryDate": "01/01/2020",
            "Office": originalSelected.officeLocation,
            "PhoneExtension": originalSelected.businessPhones,
            "Email": originalSelected.mail,
            "StaffID": originalSelected.StaffID,
            "HESAID": originalSelected.HESAID,
            "ContractType": originalSelected.ContractType
        }

        axios.post(`/edit-staff/${props.user.id}`, editUserObject)
        .then(res => {
            props.setMessage({
                type: "success",
                title: "User has been updated",
                message: "The user has been updated. Please allow up to 30 minutes for the user to be provisoned in Azure"
            })
        })
        .catch(err => {
            props.setMessage({
                type: "success",
                title: "User was not updated",
                message: "Sorry, there has been a problem and the user has not been updated. Please try again."
            })            
        })
    }

    return(
        <form className={classes.UserForm} onSubmit={(e) => sendEditUserHandler(e)}>
            <div className={classes.UserForm__Item}>
                <div>Given Name</div>
                <input value={originalSelected.givenName} type="text" required onChange={(e) => {onChangeHandler(e.target.value, "givenName"); setFirstname(e.target.value)}} />
            </div>   
            <div className={classes.UserForm__Item}>
                <div>Surname</div>
                <input value={originalSelected.surname} type="text" required onChange={(e) => {onChangeHandler(e.target.value, "surname"); setLastname(e.target.value)}} />
            </div> 
            <div className={classes.UserForm__Item}>
                <div>Display Name</div>
                <input disabled value={originalSelected.displayName} />
            </div>                            
            {/* <div className={classes.UserForm__Item}>
                <div>Name</div>
                <input value={originalSelected.Name} type="text" required onChange={(e) => onChangeHandler(e.target.value, "Name")} />
            </div> */}
            <div className={classes.UserForm__Item}>
                <div>SamAccountname</div>
                <input value={originalSelected.onPremisesSamAccountName} disabled />
            </div>
            
            <div className={classes.UserForm__Item}>
                <div>Staff ID</div>
                <input value={originalSelected.StaffID} type="number" onChange={(e) => {onChangeHandler(e.target.value, "StaffID")}} />
            </div>
            <div className={classes.UserForm__Item}>
                <div>HESA ID</div>
                <input value={originalSelected.HESAID} type="number" onChange={(e) => {onChangeHandler(e.target.value, "HESAID")}} />
            </div>            
            
            <div className={classes.UserForm__Item}>
                <div>Contract Type</div>
                <select defaultValue="" value={originalSelected.ContractType} required onChange={(e) => {onChangeHandler(e.target.value, "ContractType")}}>
                <option value="" disabled>Select...</option>
                <option value="FTE">FTE</option>
                <option value="VL/Casual">VL/Casual</option>
                <option value="Off-payroll">Off-Payroll</option>
                </select>
            </div>            
            <div className={classes.UserForm__Item}>
                <div>Programme Code</div>
                <select defaultValue="" value={originalSelected.companyName} required onChange={(e) => {onChangeHandler(e.target.value, "companyName"); onChangeHandler(e.target.value, "ProgrammeCode"); setSelectedProgrammeCode(e.target.value)}}>
                <option value="" disabled>Select...</option>
                    {
                        programmeCode ?
                            programmeCode.map(item => 
                                <option key={item.Selection} value={item.Selection}>{item.Selection}</option>
                            ) 
                        : null
                    }
                </select>
            </div>
            {/* <div className={classes.UserForm__Item}>
                <div>Category</div>
                <select value={originalSelected.department} onChange={(e) => onChangeHandler(e.target.value, "department")}>
                    <option value="Select" disabled>Select...</option>
                    <option value="Administration">Administration</option>
                    <option value="Buildings &amp; Estates">Buildings &amp; Estates</option>
                    <option value="Estates &amp; Campus Operations">Estates &amp; Campus Operations</option>
                    <option value="Finance">Finance</option>
                    <option value="ILTS">ILTS</option>
                    <option value="Services &amp; Support">Services &amp; Support</option>
                    <option value="School of Communication">School of Communication</option>
                    <option value="School of Architecture">School of Architecture</option>
                    <option value="School of Arts &amp; Humanities">School of Arts &amp; Humanities</option>
                    <option value="School of Design">School of Design</option>
                    <option value="Graduate Diploma Art &amp; Design">Graduate Diploma Art &amp; Design</option>
                    <option value="Research &amp; Innovation">Research &amp; Innovation</option>
                </select>
            </div> */}
            {/* <div className={classes.UserForm__Item}>
                <div>Course</div>
                <input value={originalSelected.course} type="text" required onChange={(e) => onChangeHandler(e.target.value, "Course")} />
            </div>  
            <div className={classes.UserForm__Item}>
                <div>Description</div>
                <input value={originalSelected.description} type="text" required onChange={(e) => onChangeHandler(e.target.value, "Description")} />
            </div>                                        */}
            {/* <div className={classes.UserForm__Item}>
                <div>Start Date</div>
                <input value={originalSelected.startMonth} type="date"  onChange={(e) => onChangeHandler(moment(e.target.value).format("DD/MM/YYYY"), "startMonth")} />
            </div>               */}
            {/* <div className={classes.UserForm__Item}>
                <div>Expiry Date</div>
                <input value={originalSelected.expiryDate} type="date"  onChange={(e) => onChangeHandler(moment(e.target.value).format("DD/MM/YYYY"), "expiryDate")} />
            </div>               */}
            <div className={classes.UserForm__Item}>
                <div>Job Title</div>
                <input value={originalSelected.jobTitle} type="text" required onChange={(e) => onChangeHandler(e.target.value, "jobTitle")} />
            </div>     
            <div className={classes.UserForm__Item}>
                <div>Department</div>
                <input value={originalSelected.department} type="text" required onChange={(e) => onChangeHandler(e.target.value, "department")} />
            </div> 
            {/* <div className={classes.UserForm__Item}>
                <div>Description</div>
                <input value={originalSelected.description} type="text" required onChange={(e) => onChangeHandler(e.target.value, "description")} />
            </div>              */}
            <div className={classes.UserForm__Item}>
                <div>Office</div>
                <input value={originalSelected.officeLocation} type="text" required onChange={(e) => onChangeHandler(e.target.value, "officeLocation")} />
            </div>             
            {/* <div className={classes.UserForm__Item}>
                <div>City</div>
                <input value={originalSelected.city} type="city" required onChange={(e) => onChangeHandler(e.target.value, "city")} />
            </div>   */}
            <div className={classes.UserForm__Item}>
                <div>Personal Email</div>
                <input value={originalSelected.otherMails} type="email" required onChange={(e) => onChangeHandler(e.target.value, "otherMails")} />
            </div>              
            <div className={classes.UserForm__Item}>
                <div>Phone Extension</div>
                <input value={originalSelected.businessPhones} type="number"  onChange={(e) => onChangeHandler(e.target.value, "businessPhones")} />
            </div>
            <Button type="submit">Update Staff Information</Button> 
            <div className={classes.UserForm__Message}>
                Please note: Edits made to user accounts can take up to 30 minutes to become visible
            </div>                                                                                                     
        </form>
    )
}

export default EditStaff;
