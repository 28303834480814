import React,{ useState, useEffect } from 'react';
import Button from '../Button/Button';
import axios from '../../axios';
import moment from 'moment';

const ChangePassword = (props) => {

    const [password, setPassword] = useState(null);
    const [clicked, setClicked] =  useState(false);


    useEffect(() => {
        setClicked(false);
    }, [])
    
    const changePasswordHandler = () => {
        props.setMessage({
            type: "save",
            title: "Resetting Password",
            message: "The password is being reset, please wait"
        })



        setClicked(true);
        axios.post('/reset-password', { email: props.selected.onPremisesSamAccountName})
        .then(res => {
            setPassword(res.data.password);
            props.setMessage({
                type: "success",
                title: "Password Reset",
                message: "The password has been reset"
            });

            // const time = moment().format("DD-MM-YYYY HH:mm");
            // console.log(time)
            // // const people = props.people
            // // console.log("people", people)
            // // const person = people.find(item => item.id = props.selected.id);
            // // person.lastPasswordChangeDateTime = time
            // // console.log("person changed", person)
            // // // props.setPeople(...people)
            // props.setPeople(...props.people.map(person => {
            //     if(person.id === props.selected.id) {
            //         console.log(person)
            //         return {...person, lastPasswordChangeDateTime: time}
            //     } else { 
            //         return person
            //     }
            // }))

        })
        .catch(err => {console.log(err); setClicked(false)})
    }

    return(
        <>
        <div>Are you sure you want to change this users password? Clicking change password will complete this process and notify you of the new password on screen.</div>
        <br/>
        {   
            password ?
                <>
                    <span>Password has been set as: </span>
                    <strong>{password}</strong>
                    <br />
                    <br />
                </>
            : null
        }
        <Button disabled={clicked} onClick={() => changePasswordHandler()}>Change this users password</Button>
        </>
    )
}

export default ChangePassword