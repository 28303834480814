import React from 'react';

import classes from './Button.module.scss';

const Button = (props) => {

    return(
        <button
            className={[classes.Button, ...[props.className]].join(' ')}
            onClick={ props.onClick ? (e) => props.onClick(e) :  null}
            title={ props.title }
            disabled={ props.disabled }
            id={ props.id }
            type={props.type}>
            {props.children}
        </button>
    )
}

export default Button;