import React from 'react';

import classes from './Input.module.scss';

const Input = (props) => {
    return(
        <input 
            className={classes.Input}
            onClick={props.onClick ? (e) => props.onClick(e) : null}
            onChange={props.onChange ? (e) => props.onChange(e) : null} 
            value={props.value} 
            type={props.type}
            defaultValue={props.defaultValue} 
            onBlur={props.onBlur ? (e) => props.onBlur(e) : null}
            placeholder={props.placeholder} />
    )
}

export default Input