import React from 'react';

import classes from './Header.module.scss';
import { ReactComponent as Logo } from  '../../images/logo.svg';

const Header = (props) => {

    return(
        <header className={classes.Header}>
            <div className={classes.Header__Logo}><Logo /></div>
            <div className={classes.Header__Options}>
                {
                    props.options ?
                        props.options.map((item, index) =>
                            <span key={index}>{item}</span> 
                        )
                    : null
                }
            </div>
            <h1 className={classes.Header__Title}>{props.title}</h1>
        </header>
    )
}
export default Header