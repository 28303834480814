import React, { useEffect, useState } from 'react';
import { loginRequest } from "../../authConfig";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import classes from './Unauthenticated.module.scss';
import Button from '../../components/Button/Button';
import { ReactComponent as Logo } from  '../../images/logo.svg';



const Unauthenticated = (props) => {

	const { instance } = useMsal();

    const signIn = () => {
        instance.loginRedirect(loginRequest)
        .then(res => {
            console.log(res)
        })
        .catch(e => {
            console.log(e);
        });
    }

    return(
        <div className={classes.unauthenticated}>
            <div className={classes.unauthenticated__content}>
                <Logo />
                <h1>You need to sign in</h1>
                <Button onClick={() => { signIn() }}>Sign in now</Button>
            </div>
        </div>
    )
}

export default Unauthenticated;