import React from 'react';

import classes from './Select.module.scss';

const Select = (props) => {

    return(
        <select
            className={classes.Select}
            onChange={props.onChange ? (e) => props.onChange(e) : null }
            value={props.value}
            defaultValue={props.defaultValue}>
            {props.children}
        </select>
    )
}

export default Select